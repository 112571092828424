/* ========
archive-case
======== */

.o-archive-case{
  padding: 100px 0;
  @include mq(sp) {
    padding: 64px 0;
  }
  .l-inner{
    width: 1100px;
    background-color: palettes(main,lightest);
    padding: 80px 50px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    @include mq(sp) {
      width: calc(100% - 30px);
      padding: 40px 20px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    top: 20px;
    right: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 20px;
      right: -8px;
    }
    svg{
      fill: #fff;
    }
  }
  .c-ttl-serif{
    margin: 8px 0;
  }
  &__desc{
    margin: 40px 0;
    @include mq(sp) {
      margin: 24px 0;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    .c-item-case{
      @include mq(pc) {
        margin: 0 50px 56px 0;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include mq(sp) {
        margin: 24px 0 0;
      }
    }
  }
}