//-----------------------
.c-ttl-middle{
  font-family: $cinzelD;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: .15em;
  line-height: 1.5;
  @include mq(sp) {
    font-size: 2.3rem;
  }
}