//-----------------------
.o-remake-flow {
  .c-box-deco-caption {
    &__row {
      align-items: flex-start;
    }
    &__textarea {
      .c-ttl-underline {
        letter-spacing: .08em;
        @include mq(pc) {
          font-size: 2.2rem;
          .hd {
            font-size: 3.2rem;
            margin-right: 16px;
          }
        }
        @include mq(sp) {
          line-height: 1.4;
          display: flex;
          align-items: flex-start;
          margin: 20px 0;
          padding: 0 0 8px;
          .hd {
            line-height: .75;
          }
        }
        & + .c-desc {
          @include mq(pc) {
            margin: -20px 0 20px;
          }
        }
      }
    }
    &__img {
      @include mq(pc) {
        width: 320px;
      }
    }
  }
  &__box {
    background-color: palettes(main,lightest);
    display: flex;
    align-items: center;
    padding: 30px 60px;
    margin: 60px 110px 0;
    @include mq(sp) {
      display: block;
      padding: 24px 20px;
      margin: 32px 0 0;
    }
  }
  &__copy {
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    white-space: nowrap;
    @include mq(sp) {
      line-height: 1.4;
      font-size: 2rem;
    }
  }
  &__textarea {
    margin: 0 0 0 40px;
    @include mq(sp) {
      margin: 1em 0 0;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      dt, dd {
        width: 50%;
        @include mq(sp) {
          width: 100%;
        }
      }
      dd {
        font-weight: 700;
        @include mq(sp) {
          margin: 0 0 0 1em;
        }
      }
    }
  }
  &__note {
    display: flex;
    line-height: 1.6;
    margin: .5em 0 0;
    &:before {
      content: '※';
    }
  }
}