/* ========
archive-dress
======== */

.o-archive-dress{
  // background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 56px;
  }
  .l-inner{
    @include mq(pc) {
      width: 1100px;
    }
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  .c-ttl-item{
    text-align: center;
    + .c-desc{
      display: block;
      text-align: center;
    }
  }
  &__sort{
    display: flex;
    justify-content: center;
    margin: 64px 0 48px;
    @include mq(sp) {
      flex-wrap: wrap;
      margin: 40px 10px 32px;
    }
    li{
      display: flex;
      align-items: center;
      margin: 0 -1px 0 0;
      &:before, &:after{
        content: '';
        display: block;
        width: 1px;
        height: 1.8rem;
        background-color: currentColor;
        @include mq(sp) {
          background-color: #aaa;
        }
      }
      @include mq(pc) {
        &:first-child{
          &:before{
            content: none;
          }
        }
        &:last-child{
          &:after{
            content: none;
          }
        }
      }
      &.-current{
        a{
          color: #fff;
          &:before{
            opacity: 1;
          }
        }
      }
      a{
        padding: 2px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .1em;
        font-family: $cormorant;
        position: relative;
        z-index: 0;
        line-height: 1.2;
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -1px;
          right: -1px;
          bottom: 0;
          background-color: palettes(main);
          z-index: -1;
          opacity: 0;
        }
      }
    }
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    @include mq(sp) {
      margin: 0 -5px;
    }
  }
  &__item{
    width: 360px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    background: transparent center center no-repeat;
    background-size: cover;
    &:before{
      content: '';
      display: block;
      padding: 127% 0 0;
    }
    @include mq(pc) {
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq(sp) {
      margin: 0 5px 10px;
      // flex: 1 1 auto;
      width: calc(50% - 10px);
    }
  }
  .c-btn{
    width: 900px;
    margin: 32px auto 0;
    @include mq(sp) {
      width: 100%;
      margin: 24px auto 0;
    }
    >span{
      cursor: pointer;
    }
  }
}