//-----------------------
.c-ttl-mix{
  line-height: 1.6;
  text-align: center;
  &__en{
    font-family: $cinzelD;
    font-size: 3.4rem;
    letter-spacing: .15em;
    color: palettes(main);
    span{
      &:nth-child(n+2){
        margin: 0 0 0 .25em;
      }
    }
  }
  .-quentin{
    font-family: $quentin;
    font-size: 4.4rem;
  }
}