/* ========
intro-visual
======== */

.o-intro-visual{
  padding: 112px 0;
  @include mq(sp) {
    padding: 64px 0;
  }
  .l-inner{
    width: 1000px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: 100%;
      padding: 0 20px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    bottom: -120px;
    left: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 140px;
      left: auto;
      right: -8px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  &__textarea{
    width: 460px;
    @include mq(sp) {
      width: 100%;
    }
    .c-ttl-item{
      & + .c-desc{
        margin: 0 0 1.5em;
      }
    }
  }
  &__img01{
    position: absolute;
    width: 460px;
    top: -176px;
    left: 540px;
    @include mq(sp) {
      width: 100%;
      position: static;
      margin: 40px 0 0;
    }
  }
  &__img02{
    width: 380px;
    margin: 64px 0 0 80px;
    @include mq(sp) {
      display: none;
    }
  }
  &__img03{
    width: 300px;
    margin: -120px 0 0 540px;
    @include mq(sp) {
      display: none;
    }
  }
}