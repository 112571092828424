/* ========
detail-plan
======== */

.o-detail-plan{
  background: palettes(main,lightest);
  padding-bottom: 120px;
  @include mq(sp){
    padding-bottom: 50px;
  }
  .l-inner{
    max-width: 1000px;
    padding: 80px 0 0;
    @include mq(sp){
      padding: 40px 20px;
    }
  }
  &__content{
    background: #fff;
    margin: 0 auto;
    padding: 50px 60px 80px;
    @include mq(sp){
      padding: 30px 20px;
    }
    .c-copy-large{
      color: $color_text;
      font-weight: 450;
      text-align: center;
    }
    .o-detail-plan__desc{
      display: flex;
      flex-direction: column;
      align-items: center;
      .c-item-label{
        margin-top: 20px;
        @include mq(sp){
          margin-top: 12px;
        }
      }
      .c-desc{
        width: 100%;
        text-align: center;
        margin-top: 20px;
        @include mq(sp){
          margin-top: 12px;
        }
      }
      .c-copy-price{
        margin-top: 16px;
        @include mq(sp){
          margin-top: 12px;
        }
      }
    }
    .o-detail-plan__photo{
      @include mq(sp){
        margin-top: 30px;
      }
      &.-first{
        margin-top: 48px;
        @include mq(sp){
          margin-top: 50px;
          figure{
            margin: 0 -40px;
          }
        }
      }
      .c-desc{
        margin-top: 24px;
        @include mq(sp){
          margin-top: 12px;
        }
      }
    }
    .o-detail-plan__col2{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include mq(sp){
        display: block;
      }
      .o-detail-plan__photo{
        width: calc((100% - 45px)/2);
        margin: 40px 0 0;
        @include mq(sp){
          width: 100%;
        }
      }
    }
    .o-detail-plan__list{
      margin-top: 80px;
      @include mq(sp){
        margin-top: 40px;
      }
    }
    .c-box-card{
      margin-top: 24px;
    }
  }
  .o-lead-contact02{
    @include mq(pc){
      margin-top: 80px;
    }
  }
  .c-btn.u-center{
    width: 300px;
    margin-top: 80px;
    @include mq(sp){
      margin-top: 40px;
    }
  }
  .o-unit-infomation{
    margin-top: 90px;
    @include mq(sp){
      margin-top: 40px;
    }
    .l-inner{
      padding: 0;
      @include mq(sp){
        padding: 0 20px;
      }
    }
  }
}