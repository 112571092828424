.c-item-simple{
  width: 300px;
  &__img{
    background: transparent center center no-repeat;
    background-size: cover;
    margin: 0 0 16px;
    border: 4px solid #fff;
    @include mq(sp) {
      border-width: 3px;
    }
    &:before{
      content: '';
      display: block;
      padding: 66.66% 0 0;
    }
    img{
      display: none;
    }
  }
  .c-copy-basic{
    letter-spacing: .1em;
    text-align: center;
    margin: 0 0 8px;
    line-height: 1.6;
  }
}