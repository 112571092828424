/* ========
detail-case
======== */

.o-detail-case{
  background-color: #f1f6f7;
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 56px;
  }
  &__head{
    padding: 56px 0 120px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 32px 0;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -190px;
      z-index: -1;
      background-color: palettes(blue,lightest);
      pointer-events: none;
      @include mq(sp) {
        bottom: -100px;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 0;
    width: 1100px;
    background-color: #fff;
    padding: 50px;
    @include mq(sp) {
      width: calc(100% - 30px);
      padding: 20px;
    }
    .c-box-img-types{
      margin: 100px 0 0;
      @include mq(sp) {
        margin: 64px 0 0;
      }
      &:first-child{
        position: relative;
        z-index: 0;
        margin: 0 0 160px;
        @include mq(sp) {
          margin: 0 0 96px;
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: calc(100% + 60px);
          width: 1px;
          height: 40px;
          background-color: palettes(main);
          margin: 0 auto;
          @include mq(sp) {
            top: calc(100% + 32px);
            height: 32px;
          }
        }
        .c-box-img-types__img{
          @include mq(pc) {
            width: 500px;
            figure{
              top: -100px;
              left: -140px;
              &:before{
                padding: 65.6% 0 0;
              }
            }
          }
        }
      }
    }
  }
}