/* ========
list-propose-choise
======== */

.o-list-propose-choise{
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  .l-inner{
    width: 1000px;
    @include mq(sp) {
      width: 100%;
      padding: 0 15px;
    }
    &>.c-ttl-section{
      margin-bottom: 20px;
    }
    &>.c-copy-basic{
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 50px;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    .c-item{
      width: calc(25% - 8px);
      margin: 0 4px 8px 4px;
      background-color: #fff;
      @include mq(sp) {
        width: calc(50% - 8px);
      }
      &__img{
        width: 100%;
        position: relative;
        z-index: 0;
        &:before{
          content: '';
          display: block;
          padding: 75% 0 0;
        }
        figure{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent center center no-repeat;
          background-size: cover;
          &:before{
            content: '';
            display: block;
            padding: 75% 0 0;
          }
          img{
            display: none;
          }
        }
      }
      &__textarea{
        padding: 16px 20px;
        .c-desc{
          margin-bottom: 16px;
        }
        .c-price{
          font-weight: 600;
        }
        .c-note {
          font-size: 1.3rem;
          line-height: 1.5;
          padding-left: 1em;
          &:before{
            content: "※";
            margin-left: -1em;
          }
        }
      }
    }
  }
}