/* ========
hero-simple
======== */

.o-hero-simple{
  height: auto;
  background: #fff;
  @include mq(sp){
    height: auto;
  }
  .o-hero-caption__textarea{
    width: 100%;
    padding: 70px 0;
    @include mq(sp){
      padding: 40px 0;
    }
    .c-ttl-section{
      margin: 0;
      .c-ttl-section__ja{
        font-size: 1.6rem;
      }
    }
  }
}