.lp-price-table {
  @at-root .c-lp-benefit__content + & {
    margin: 100px 0 0;
    @include mq(sp) {
      margin: 64px 0 0;
    }
  }
  &__ttl {
    font-size: 2.4rem;
    letter-spacing: .08em;
    line-height: 1.5;
    text-align: center;
    font-weight: 500;
    margin: 0 0 32px;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  .c-box-case {
    margin: 0 auto;
    width: 100%;
    @include mq(sp) {
      width: calc(100% - 40px);
    }
  }
}