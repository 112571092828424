//-----------------------
.c-btn-more{
  margin: 0 auto;
  text-align: center;
  width: 470px;
  height: 35px;
  @include mq(sp){
    width: 100%;
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    border-top: 1px solid $color_main;
    border-bottom: 1px solid $color_main;
    span{
      padding: 8px 0;
      font-size: 1.5rem;
      line-height: 1.8;
      letter-spacing: .02em;
      color: $color_main;
      position: relative;
      &:after{
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border: 1px solid;
        border-color:  transparent transparent $color_main $color_main;
        transform: rotate(-45deg);
        position: absolute;
        right: -20px;
        top: calc(50% - 6px);
      }
    }
  }
}