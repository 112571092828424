/* ========
intro-fair
======== */

.o-intro-fair{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 20px 0 0;
  .l-inner{
    padding: 0 50px;
    @include mq(sp) {
      padding: 0 30px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    top: -180px;
    right: calc(50% - 640px);
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: -80px;
      right: -8px;
    }
    svg{
      fill: #fff;
    }
  }
  .c-ttl-section{
    margin: 0 0 80px;
    @include mq(sp) {
      margin: 0 0 24px;
    }
  }
  &__content{
    padding: 20px 0 56px;
    @include mq(sp) {
      padding: 0;
    }
    .c-slider-fair{
      .slick-list{
        overflow: visible;
      }
    }
    .slick-slide{
      pointer-events: none;
      &.slick-current{
        pointer-events: auto;
      }
    }
    .c-item-fair{
      @include mq(pc) {
        &__img{
          width: calc(50% + 40px);
          transform: translate(-40px,-40px);
          margin: 0 -40px 0 0;
          &:before{
            padding: 72% 0 0;
          }
        }
        &__textarea{
          padding: 40px 40px 80px;
        }
        &__btn{
          right: 40px;
          bottom: 40px;
        }
      }
      &__date{
        &__text{
          .-date{
            @include mq(pc) {
              letter-spacing: .1em;
            }
            @include mq(sp) {
              font-size: 3rem;
            }
          }
          .-dot{
            @include mq(pc) {
              letter-spacing: .1em;
            }
            @include mq(sp) {
              font-size: 3rem;
            }
          }
          .-day{
            @include mq(sp) {
              font-size: 1.2rem;
            }
          }
        }
      }
      .c-desc{
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  &__foot{
    background-color: darken(palettes(main,lightest),4.5%);
    @include mq(sp) {
    background-color: transparent;
    }
    .l-inner{
      position: relative;
      z-index: 0;
      @include mq(sp) {
        padding: 0;
      }
    }
    .c-slider-fair-dot{
      padding: 32px 0;
      @include mq(sp) {
        padding: 20px 0;
      }
      &__arrow{
        width: 50px;
        height: 50px;
        background-color: #000;
        position: absolute;
        background: transparent center center no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 16 28'%3E%3Cdefs/%3E%3Cpath fill='%23997751' d='M16 0h-2L0 14l14 14h2L2 14z'/%3E%3C/svg%3E");
        background-size: 16px 28px;
        cursor: pointer;
        z-index: 1;
        @include mq(sp) {
          background-size: 12px 21px;
          width: 32px;
          height: 32px;
          display: none !important;
        }
        &.-prev{
          top: 50%;
          left: 40px;
          transform: translate(0,-50%);
          @include mq(sp) {
            left: 24px;
          }
        }
        &.-next{
          top: 50%;
          right: 40px;
          transform: translate(0,-50%)scale(-1,1);
          @include mq(sp) {
            right: 24px;
          }
        }
      }
      .slick-dots{
        counter-reset: sliderNum;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translate(0,-70%);
        @include mq(sp) {
          position: relative;
          transform: none;
        }
        li{
          margin: 0 8px;
          position: relative;
          z-index: 0;
          cursor: pointer;
          @include mq(sp) {
            margin: 0 4px;
          }
          &:before, &:after{
            transition: .4s ease;
          }
          &:before{
            counter-increment: sliderNum 1;
            content: counter(sliderNum,decimal-leading-zero);
            font-family: $cormorant;
            font-weight: 400;
            font-size: 1.8rem;
            letter-spacing: .1em;
            text-align: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            color: palettes(main);
            border: 2px solid #fff;
            @include mq(sp) {
              width: 24px;
              height: 24px;
              border-width: 3px;
              font-size: 1.4rem;
              text-indent: .1em;
            }
          }
          &:after{
            content: '';
            width: 0;
            height: 0;
            border-width: 12px 6px 0 6px;
            border-style: solid;
            border-color: palettes(main,light) transparent transparent transparent;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -6px;
            margin: 0 auto;
            z-index: -1;
            opacity: 0;
            @include mq(sp) {
              content: none;
            }
          }
          button{
            display: none;
          }

          &.slick-active{
            &:before{
              background-color: palettes(main);
              color: #fff;
              border-color: palettes(main,light);
            }
            &:after{
              opacity: 1;
            }
          }
        }
      }
      &__ttl{
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: .1em;
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
}