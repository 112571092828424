/* ========
detail-fair
======== */

.o-detail-fair{
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 56px;
  }
  >.c-btn{
    margin: 56px auto 0;
    @include mq(sp) {
      width: 208px;
      margin-top: 32px;
    }
  }
  &__main{
    position: relative;
    figure{
      width: 640px;
      background: transparent center center no-repeat;
      background-size: cover;
      position: absolute;
      left: -90px;
      top: 50%;
      transform: translate(0,-50%);
      @include mq(sp) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: auto;
        margin: 0 0 0 -30px;
      }
      &:before{
        content: '';
        display: block;
        padding: 75% 0 0;
      }
      img{
        display: none;
      }
    }
    &__head, &__foot{
      padding: 40px 40px 40px 600px;
      @include mq(sp) {
        padding: 30px;
        margin: 0 -30px 0 0;
      }
      dl{
        display: flex;
        align-items: flex-start;
        @include mq(sp) {
          display: block;
          &:nth-child(n+2){
            margin: .75em 0 0;
          }
        }
        &.c-desc{
          line-height: 1.8;
        }
      }
      dt{
        margin: 0 .5em 0 0;
        flex: 0 0 auto;
        @include mq(sp) {
          margin: 0;
        }
      }
    }
    &__head{
      background-color: #fff;
      .c-btn{
        width: 100%;
      }
      .c-ttl-small{
        margin: 0 0 1em;
        padding: 0 0 1em;
        border-bottom: 1px solid palettes(main,light);
        @include mq(sp) {
          font-size: 1.9rem;
        }
      }
    }
    &__foot{
      background-color: #e0d9d3;
    }
  }
  &__reserve{
    padding: 72px 50px;
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      padding: 32px 0 64px;
      display: block;
      // flex-direction: column-reverse;
    }
    .o-grp-calendar{
      @include mq(sp) {
        margin: 40px 0 0;
      }
      .c-calendar{
        table{
          td{
            cursor: default;
            &:nth-child(1){
              background-color: #fff;
            }
            &:nth-child(7){
              background-color: #fff;
            }
            &.-active{
              background-color: palettes(main,light);
              color: #fff;
              cursor: pointer;
              &.-current{
                background-color: palettes(main);
              }
            }
          }
        }
      }
    }
  }
  &__content{
    padding: 80px 50px 8px;
    background-color: #eae6e2;
    @include mq(sp) {
      padding: 40px 25px 8px;
    }
    .c-ttl-small{
      margin: 0 0 40px;
      text-align: center;
      @include mq(sp) {
        margin: 0 0 32px;
        font-size: 1.8rem;
      }
    }
    ul{
      display: flex;
      flex-wrap: wrap;
    }
    .c-item-simple{
      margin: 0 50px 72px 0;
      @include mq(pc) {
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @include mq(sp) {
        width: 100%;
        margin: 0 0 32px 0;
      }
    }
  }
}