/* ========
archive-information
======== */

.o-archive-information{
  background: palettes(main,lightest);
  .l-inner{
    max-width: 1000px;
    @include mq(sp){
      padding: 0 30px;
    }
    >.c-box-information{
      & + .c-box-information{
        margin: 110px 0 0;
        @include mq(sp) {
          margin: 40px 0 0;
        }
      }
    }
  }
  &__content{
    padding: 54px 0 120px;
    @include mq(sp){
      padding: 40px 0 50px;
      .c-list-sort{
        margin-bottom: 20px;
      }
    }
    .o-lead-information__list{
      padding: 0;
      @include mq(sp){
        overflow-x: visible;
        display: block;
      }
      &:before,&:after{
        display: none;
      }
      .c-item-information{
        @include mq(sp){
          margin: 40px 0 0;
        }
        a{
          position: relative;
          @include mq(sp){
            background: #fff;
          }
          
          .c-item-information__textarea{
            @include mq(sp){
              padding: 30px 30px 45px;
              margin-top: 0;
            }
          }
        }
      }
      &.-regular{
        margin: 116px 0 0;
        @include mq(sp){
          margin-top: 40px;
        }
        .c-item-information{
          width: 470px;
          @include mq(sp){
            width: 100%;
          }
          &__img{
            padding: 70% 0 0;
            figure{
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
      &.-small{
        flex-wrap: wrap;
        margin: 0;
        .c-item-information{
          margin-top: 56px;
          @include mq(sp){
            width: 100%;
            margin-top: 40px;
            &__img{
              padding: 70% 0 0;
              figure{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
        @include mq(pc){
          .c-item-information__label{
            p{
              font-size: 1.2rem;
              top: -10px;
              left: -10px;
              width: 64px;
              height: 64px;
            }
            &:before{
              border-width: 64px 64px 0 0;
            }
          }
        }
      }
    }
    .c-btn-more{
      margin-top: 48px;
      @include mq(sp){
        margin-top: 40px;
      }
    }
  }
  &__sorts{
    margin: 0 0 40px;
    @include mq(pc) {
      display: flex;
      justify-content: flex-end;
    }
    @include mq(sp) {
      margin: 0 0 24px;
    }
    .c-list-sort{
      @include mq(pc) {
        margin: 0 0 0 32px;
      }
    }
  }
  .c-item-information__label{
    z-index: 1;
    position: absolute;
    top: 1px;
    left: 1px;
    &:not([class*="-new"]){
      &:before{
        border-color: rgba(#999,.7) transparent transparent transparent;
      }
    }
    p{
      font-family: $cinzelD;
      color: #fff;
      font-size: 1.5rem;
      letter-spacing: .05em;
      line-height: 1.2;
      transform: rotate(-45deg);
      position: absolute;
      text-align: center;
      top: -16px;
      left: -16px;
      width: 96px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 96px 96px 0 0;
      border-color: $color_blue transparent transparent transparent;
      z-index: -1;
    }
  }
}