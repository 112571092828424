/* ========
intro-cathedral
======== */

.o-intro-cathedral{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  .o-hero-caption{
    @include mq(pc){
      .l-inner{
        padding: 0;
        &:before{
          bottom: -164px;
        }
      }
      &__textarea{
        padding: 72px 0 0;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 1;
    padding: 80px 0 120px;
    @include mq(sp) {
      padding: 64px 0;
    }
    .l-inner{
      max-width: 1000px;
      @include mq(sp) {
        width: 100%;
        padding: 0 20px;
      }
    }
    .c-box-images{
      position: relative;
      &:before{
        content: "";
        display: block;
        width: 340px;
        height: 200px;
        background: url(../images/ceremony/cathedral/img_cathedral02.jpg) center center no-repeat;
        background-size: cover;
        position: absolute;
        top: 160px;
        right: -46px;
        z-index: 1;
        @include mq(sp){
          display: none;
        }
      }
      &__img__main{
        @include mq(pc){
          width: 520px;
          height: 700px;
        }
        @include mq(sp){
          width: 100%;
          height: 431px;
          margin: 0 auto;
        }
      }
      &__textarea{
        padding: 72px 56px 72px;
        margin-top: 320px;
        @include mq(sp){
          margin-top: 0px;
          padding: 30px 0;
        }
        &:before{
          left: -130px;
          @include mq(sp){
            top: -50px;
            right: -130px;
          }
        }
        .c-ttl-item{
          font-size: 2.8rem;
          letter-spacing: .1em;
        }
      }
      &.-rev{
        &:before{
          content: "";
          display: block;
          width: 418px;
          height: 290px;
          background: url(../images/ceremony/cathedral/img_cathedral04.jpg) center center no-repeat;
          background-size: cover;
          position: absolute;
          top: 380px;
          right: 0;
          z-index: 2;
          @include mq(sp){
            display: none;
          }
        }
        .c-box-images__img{
          margin-top: 80px;
          @include mq(sp){
            margin-top: 64px;
          }
          &__main{
            @include mq(pc){
              width: 480px;
              height: 360px;
            }
            @include mq(sp){
              width: 100%;
              height: 240px;
              margin: 0 auto;
            }
          }
        }
        .c-box-images__textarea{
          @include mq(pc){
            margin-top: 120px;
          }
          @include mq(sp){
            &:before{
              left: -280px;
            }
          }
        }
      }
    }
    .c-img-hero{
      height: 400px;
      margin-top: 170px;
      @include mq(sp){
        height: 256px;
        margin: 32px -15px 0;
      }
    }
    .c-box-explain{
      margin-top: 100px;
      @include mq(sp){
        margin-top: 32px;
        flex-direction: column-reverse;
        &__img{
          padding-top: 0;
        }
      }
    }
  }
}