//-----------------------
.c-link-shop{
  width: 400px;
  height: 90px;
  @include mq(sp) {
    width: 100%;
    height: auto;
  }
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid palettes(main, light);
    position: relative;
    z-index: 0;
    transition: .2s ease;
    padding: 8px 16px;
    @include mq(pc) {
      &:hover{
        background-color: palettes(main,lightest);
      }
    }
  }
  &__logo{
    min-width: 140px;
    height: 32px;
    padding: 0 16px 0 0;
    @include mq(pc) {
      flex: 0 0 auto;
    }
    img{
      height: 100%;
      @include mq(sp) {
        height: auto;
        max-height: 100%;
      }
    }
  }
  .c-desc{
    text-align: center;
    line-height: 1.6;
    @include mq(sp) {
      flex: 0 0 auto;
    }
  }
  .iconSvg{
    position: absolute;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 8px;
    right: 8px;
    margin: auto 0;
    svg{
      fill: palettes(main);
    }
  }
}