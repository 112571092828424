/* ========
list-img-grid
======== */

.o-list-img-grid {
  background-color: palettes(main, lightest);
  padding-bottom: 80px;
  &__row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1004px;
    margin: 0 auto;
  }
  &__item {
    width: calc(33.333% - 4px);
    margin: 2px;
    figure{
      width: 100%;
      position: relative;
      background-size: cover;
      &:before{
        content: "";
        display: block;
        padding:72% 0 0 0;
      }
      img{
        display: none;
      }
    }
  }
}
