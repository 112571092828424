/* ========
intro-photomovie
======== */

.o-intro-photomovie {
  background-color: palettes(main, lightest);
  position: relative;
  z-index: 0;
  padding-bottom: 120px;
  @include mq(sp) {
    padding-bottom: 64px;
  }
  .l-inner {
    background-color: #fff;
    margin-top: 60px;
    padding-bottom: 60px;
    width: 1000px;
    z-index: 0;
    position: relative;
    @include mq(sp) {
      width: calc(100% - 40px);
      padding: 0 0 40px 0;
    }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  &__content {
    text-align: center;
    .c-ttl-section {
      padding-top: 50px;
      padding-bottom: 30px;
    }
    .c-desc {
      text-align: center;
      padding-bottom: 50px;
    }
    .c-btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(sp) {
        flex-direction: column;
      }
      .c-btn {
        margin: 0 5px;
        width: 320px;
        @include mq(sp) {
          width: 180px;
          &:not(:last-child){
            margin-bottom: 2px;
          }
        }
      }
    }
    .c-box-images {
      padding-top: 80px;
      @include mq(sp) {
        padding-top: 40px;
      }
      &__textarea {
        text-align: left;
        margin: 0;
        padding: 0 60px;
        @include mq(sp) {
          padding: 40px 20px 20px 20px;
        }
        .c-copy {
          font-size: 2.2rem;
          line-height: 1;
          margin: 0 0 24px 0;
          @include mq(sp) {
            margin: 0 0 16px 0;
          }
        }
        .c-desc {
          text-align: left;
          @include mq(sp) {
            padding-bottom: 0;
          }
        }
      }
      &__img {
        width: 560px;
        margin-left: -60px;
        @include mq(sp) {
          width: 100%;
          margin-left: -20px;
        }
        &__main {
          height: 320px;
          @include mq(sp) {
            height: auto;
          }
        }
        &__sub {
          width: 740px;
          margin-left: 120px;
          margin-top: -40px;
          z-index: 1;
          position: relative;
          @include mq(sp) {
            width: 100%;
            margin-top: -15px;
            margin-left: 42px;
          }
          figure:before {
            padding: 62% 0 0;
          }
        }
      }
      &.-rev {
        .c-box-images__textarea{
          &:before{
            display: none;
          }
        }
        .c-box-images__img {
          margin-left: 0;
          margin-right: -60px;
          @include mq(sp) {
            margin-left: 20px;
            margin-right: 0;
          }
          &__sub {
            margin-left: -297px;
            @include mq(sp) {
              margin-left: -42px;
            }
          }
        }
      }
    }
  }
  &__content-sub {
    padding: 60px 60px 0 60px;
    @include mq(sp) {
      padding: 40px 20px 0 20px;
    }
    .c-ttl {
      font-size: 2.8rem;
      letter-spacing: 0.2em;
      line-height: 1;
      text-align: center;
      margin-bottom: 50px;
      @include mq(sp) {
        font-size: 2.2rem;
        margin-bottom: 40px;
      }
    }
    .c-btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(sp) {
        flex-direction: column;
      }
      .c-btn {
        margin: 0 5px;
        width: 320px;
        @include mq(sp) {
          width: 180px;
          &:not(:last-child){
            margin-bottom: 2px;
          }
        }
      }
    }
    .c-box-images {
      &:not(:last-child) {
        margin-bottom: 60px;
      }
      &__img {
        width: 440px;
        @include mq(sp) {
          width: 100%;
        }
        &__main {
          height: 260px;
          @include mq(sp) {
            height: auto;
          }
        }
      }
      &__textarea {
        margin: 0;
        padding: 0 0 0 60px;
        &:before{
          display: none;
        }
        @include mq(sp) {
          padding: 20px 0;
        }
        .c-copy {
          font-size: 2.2rem;
          line-height: 1;
          margin: 0 0 24px 0;
          @include mq(sp) {
            margin: 0 0 16px 0;
          }
        }
        .c-box-simple__more{
          position: static;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          a{
            margin-left: auto;
            display: flex;
            align-items: center;
          }
        }
      }
      &.-rev {
        .c-box-images__textarea {
          padding-left: 0;
          padding-right: 60px;
        }
        .c-box-images__img__sub figure:before {
          padding: 60% 0 0;
        }
      }
    }
  }
}

.o-intro-movie-gallery {
  background-color: #fff;
  padding-bottom: 100px;
  .l-inner {
    width: 1000px;
    z-index: 0;
    position: relative;
    @include mq(sp) { 
      width: calc(100% - 40px);
      padding: 0 0 40px 0;
    }
  }
  &__content {
    text-align: center;
    .c-ttl-section {
      padding-top: 50px;
      padding-bottom: 30px;
    }
    .c-desc {
      text-align: center;
      padding-bottom: 50px;
    }
    .c-btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(sp) {
        flex-direction: column;
      }
      .c-btn {
        margin: 0 5px;
        width: 320px;
        @include mq(sp) {
          width: 180px;
          &:not(:last-child){
            margin-bottom: 2px;
          }
        }
      }
    }
    .divider {
      margin-top: 80px;
      margin-bottom: 80px;
      width: 100%;
      height: 1px;
      background-color: #f4f3f1;
    }
    .c-ttl {
      font-size: 2.2rem;
      line-height: 1;
      margin-bottom: 40px;
      letter-spacing: 0.1em;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    .c-box-movie {
      width: 332px;
      height: 240px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      @include mq(pc) {
        &:hover {
          .c-box-movie__content {
            opacity: 1;
            background-color: rgba(#1e1914, 0.5);
            pointer-events: auto;
            .c-box-movie__desc {
              opacity: 1;
            }
          }
        }
      }
      @include mq(sp) {
        width: calc(50% - 2px);
        height: auto;
        margin:1px;
      }
      a {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
        overflow: hidden;
        display: block;
      }
      &__img {
        width: 100%;
        height: 100%;
        background: transparent center center no-repeat;
        background-size: cover;
        position: relative;
        @include mq(sp) {
          height: auto;
          &:before {
            content: "";
            display: block;
            padding: 72.28% 0 0;
          }
        }
        &:after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent center center no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='visible' viewBox='0 0 120 120'%3E%3Cdefs/%3E%3Cstyle%3E.st0%7Bfill:%23fff%7D%3C/style%3E%3Cpath d='M60 0C26.9 0 0 26.9 0 60s26.9 60 60 60 60-26.9 60-60S93.1 0 60 0zm0 115C29.7 115 5 90.3 5 60S29.7 5 60 5s55 24.7 55 55-24.7 55-55 55z' class='st0'/%3E%3Cpath d='M45.5 85.7L89.9 60 45.5 34.3z' class='st0'/%3E%3C/svg%3E");
          background-size: 60px;
          @include mq(sp) {
            background-size: 18%;
          }
        }
        img {
          display: none;
        }
      }
      &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#1e1914, 0.2);
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        opacity: 1;
        pointer-events: none;
        transition: background-color 0.6s ease;
        @include mq(sp) {
          position: relative;
          opacity: 1;
          pointer-events: auto;
          padding: 0;
          background-color: rgba(30, 25, 20, 0);
        }
      }
      &__ttl {
        font-size: 1.8rem;
        line-height: 1.4;
        @include mq(sp) {
          font-size: 1.4rem;
        }
        &:after {
          content: "";
          width: 1em;
          height: 1px;
          background-color: palettes(main);
          display: block;
          margin: 0.5em auto;
        }
      }
      &__desc {
        font-size: 1.4rem;
        line-height: 1.8;
        padding: 10px;
        bottom: 0;
        left: 0;
        position: absolute;
        text-align: left;
        opacity: 0;
        transition: opacity 0.6s ease;
        @include mq(sp) {
          font-size: 1.2rem;
          bottom: 0;
        left: 0;
        position: static;
        opacity: 1;
        color: #323232;
        }
      }
    }
  }
}
