//-----------------------
.c-item-shift-box{
  a{
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        .c-item-shift-box__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    @include mq(sp) {
      display: block;
    }
  }
  &__img{
    position: relative;
    z-index: 0;
    flex: 0 0 auto;
    width: 550px;
    height: 380px;
    overflow: hidden;
    @include mq(sp) {
      width: 100%;
      height: auto;
      &:before{
        content: '';
        display: block;
        padding: 66% 0 0;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  .c-ttl-small{
    margin: 12px 0 4px;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 2.2rem;
    }
  }
  &__meta{
    line-height: 1.4;
    margin: 4px 0 16px;
  }
  .c-desc{ 
    // size
    span.-price{
      font-family: $cormorant;
      font-size: 3.3rem;
      letter-spacing: .05em;
      margin: 0 0 0 .25em;
      @include mq(sp) {
        font-size: 2.6rem;
      }
    }
  }
  &__textarea{
    padding: 50px;
    position: relative;
    margin: 40px 0 0;
    @include mq(sp) {
      margin: 0;
      padding: 24px;
      position: static;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -274px;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: -1;
      pointer-events: none;
      @include mq(sp) {
        left: 0;
      }
    }
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 104px;
      background: transparent url(../images/common/img_label01.png) center center no-repeat;
      background-size: contain;
      position: absolute;
      top: -40px;
      right: 60px;
      z-index: 1;
      @include mq(sp) {
        width: 67px;
        top: -18px;
        right: 10px;
      }
    }
  }

  // layout
  &.-rev{
    @include mq(pc) {
      a{
        flex-direction: row-reverse;
      }
      .c-item-shift-box__textarea{
        &:before{
          left: 0;
          right: -274px;
        }
      }
    }
  }
}