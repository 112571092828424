/* ========
detail-report
======== */

.o-detail-report{
  .o-archive-report__head{
    padding: 100px 0 120px;
    @include mq(sp) {
      padding: 32px 0;
    }
  }
  &__content{
    z-index: 0;
    @include mq(sp) {
      width: calc(100% - 30px);
      padding: 64px 0;
      margin: 0 auto;
    }
    .l-inner{
      max-width: 1100px;
    }
    .c-box-report{
      background: #fff;
      z-index: 1;
      @include mq(pc){
        transform: translateY(-40px);
      }
      .c-box-report__main{
        .c-box-report__img{
          @include mq(pc){
            transform: translate(-50px,-60px);
            height: 540px;
          }
        }
        .c-box-report__textarea{
          padding: 64px 0;
          &:after{
            display: none;
          }
          @include mq(pc){
            .c-box-report__ttl{
              line-height: 1;
              margin-top: 4px;
            }
            .c-box-report__subttl{
              margin-top: 20px;
            }
            .c-list-definition{
              margin-top: 50px;
            }
          }
          @include mq(sp){
            padding: 0;
            .c-list-definition{
              margin: 20px 0 0;
            }
          }
        }
      }
      .c-box-report__article{
        margin: 0 0 48px;
        @include mq(pc){
          display: flex;
        }
        .c-box-report__txt{
          width: 50%;
          padding: 0 18px 0 60px;
          @include mq(sp){
            width: 100%;
            padding: 0 20px;
          }
          >div{
            &:nth-of-type(n+2){
              margin-top: 30px;
            }
          }
          .c-copy{
            color: $color_main;
            span{
              margin-right: 8px;
            }
          }
          .c-desc{
            margin-top: 4px;
          }
          .c-btn-more{
            margin-top: 30px;
            margin-bottom: 50px;
            @include mq(sp){
              margin: 30px 0 0;
              a {
                padding: 0;
              }
            }
          }
        }
        .c-box-report__phbox{
          width: 50%;
          padding: 12px 60px 0 40px;
          @include mq(sp){
            width: 100%;
            padding: 64px 20px 0;
            // display: flex;
            // justify-content: center;
            figure {
              background-repeat: no-repeat;
            }
          }
        }
      }
      .c-box-report__phbottom{
        @include mq(pc){
          position: relative;
          .c-box-report__phmain{
            padding: 0 120px 120px 60px;
          }
          .c-box-report__phsub{
            position: absolute;
            bottom: 40px;
            right: -50px;
          }
        }
        @include mq(sp){
          padding: 1px 20px 32px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          figure {
            background-repeat: no-repeat;
          }
          .c-box-report__phsub {
            margin-top: 1px;
          }
        }
      }
    }
    .c-item-pager{
      padding-bottom: 88px;
      @include mq(sp){
        padding: 0;
        margin-top: 64px;
      }
    }
  }
}