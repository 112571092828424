.c-lp-benefit {
  padding: 110px 0 90px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  @include mq(sp) {
    padding: 80px 0 56px;
  }
  &__deco {
    width: 315px;
    height: 390px;
    position: absolute;
    top: 0;
    left: calc(50% + 340px);
    z-index: -1;
    svg {
      fill: palettes(main,lightest);
    }
  }
  &__ttl {
    font-size: 3.6rem;
    text-align: center;
    line-height: 1.4;
    margin: 0 0 40px;
    @include mq(sp) {
      font-size: 2.8rem;
    }
    span {
      font-family: $cinzelD;
      letter-spacing: .05em;
      font-weight: 400;
      display: block;
    }
    small {
      font-size: 1.2rem;
      letter-spacing: .05em;
      font-weight: 500;
      display: block;
    }
  }
  &__content {
    padding: 0 88px;
    counter-reset: benefit;
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  &__item {
    position: relative;
    z-index: 0;
    background-color: palettes(main,lightest);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
    margin: 0 0 32px;
    @include mq(sp) {
      padding: 20px 16px;
      text-align: center;
      display: block;
    }
    &.-only {
      &:before {
        content: none;
      }
    }
    &:before {
      counter-increment: benefit 1;
      content: counter(benefit, decimal-leading-zero);
      font-family: $cinzelD;
      font-weight: 400;
      font-size: 3.6rem;
      letter-spacing: .05em;
      color: palettes(main);
      position: absolute;
      top: 0;
      left: .5em;
      transform: translate(0,-50%);
      @include mq(sp) {
        font-size: 2.8rem;
      }
    }
    p {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: .05em;
      flex: 0 0 auto;
      @include mq(sp) {
        display: block;
        font-size: 1.8rem;
        line-height: 1.6;
      }
    }
    small {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .05em;
      margin: 0 0 0 2em;
      @include mq(sp) {
        display: block;
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }
}