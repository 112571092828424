//-----------------------
.c-ttl-line-top{
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: .02em;
  text-align: center;
  &:before{
    content: '';
    display: block;
    width: 1px;
    height: 40px;
    background-color: palettes(main);
    margin: 0 auto 24px;
  }
}