//-----------------------
.c-item-fair{
  position: relative;
  z-index: 0;
  > a{
    display: flex;
    position: relative;
    z-index: 0;
    background-color: #fff;
    @include mq(pc) {
      &:hover{
        .c-item-fair__img{
          figure{
            transform: scale(1.06);
          }
        }
        .c-item-fair__btn{
          background-color: palettes(main,dark);
        }
      }
    }
    @include mq(sp) {
      display: block;
    }
  }
  &__img{
    width: 50%;
    flex: 0 0 auto;
    position: relative;
    z-index: 0;
    overflow: hidden;
    align-self: flex-start;
    @include mq(sp) {
      width: 100%;
    }
    &:before{
      content: '';
      display: block;
      padding: 68% 0 0;
    }
    figure{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url('image.png') center center no-repeat;
      background-size: cover;
      z-index: 0;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  &__label{
    font-size: 1.4rem;
    letter-spacing: .1em;
    background-color: palettes(main);
    font-weight: 500;
    width: 80px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    @include mq(sp) {
      font-size: 1.5rem;
      height: 35px;
    }
  }
  &__textarea{
    background-color: #fff;
    padding: 40px 40px 56px;
    position: relative;
    z-index: 0;
    width: 100%;
    @include mq(sp) {
      padding: 30px 30px 48px;
    }
  }
  &__date{
    padding: 0 0 20px;
    margin: 0 0 20px;
    border-bottom: 1px solid palettes(main,light);
    &__small{
      font-family: $roboto;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .1em;
      line-height: 1.4;
      margin: 0 0 4px;
    }
    &__text{
      line-height: 1;
      & + & {
        margin: 12px 0 0;
      }
      .-date{
        font-family: $cormorant;
        font-size: 4.2rem;
        font-weight: 400;
        letter-spacing: .2em;
      }
      .-dot{
        font-size: 4.2rem;
        font-weight: 300;
        letter-spacing: .2em;
      }
      .-day{
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .1em;
      }
      .-time{
        font-family: $roboto;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: .1em;
      }
    }
  }
  .c-copy-basic{
    letter-spacing: .1em;
    line-height: 1.7;
    margin: 0 0 8px;
  }
  .c-desc{
    line-height: 1.9;
  }
  &__btn{
    width: 112px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: palettes(main);
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: $cormorant;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: .1em;
    color: #fff;
    transition: background .4s ease;
    @include mq(sp) {
      width: 88px;
      height: 36px;
      font-size: 1.4rem;
    }
  }
  &__btn2{
    position: absolute;
    right: 112px;
    bottom: 0;
    @include mq(sp) {
      right: 88px;
    }
    a {
      width: 112px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $cormorant;
      font-weight: 400;
      font-size: 1.5rem;
      letter-spacing: .1em;
      color: #fff;
      background-color: palettes(pink);
      transition: background .4s ease;
      @include mq(pc) {
        &:hover {
          background-color: palettes(pink,dark);
        }
      }
      @include mq(sp) {
        width: 88px;
        height: 36px;
        font-size: 1.4rem;
      }
    }
  }

  // decoration
  &.-pickup{
    a{
      &:before{
        content: '';
        display: block;
        width: 80px;
        height: 104px;
        background: transparent url(../images/common/img_label01.png) center center no-repeat;
        background-size: contain;
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: 1;
        @include mq(sp) {
          width: 67px;
          top: -18px;
          left: -15px;
        }
      }
    }
  }
}