/* ========
detail-gift
======== */

.o-detail-gift {
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
  padding: 0 0 64px;
  }
  .l-inner {
  width: 1000px;
  position: relative;
  z-index: 0;
  .c-ttl-section{
    padding-bottom: 40px;
    @include mq(sp) {
      padding-top: 35px;
      padding-bottom: 20px;
    }
  }
  @include mq(sp) {
    width: 100%;
    padding: 0 15px;
  }
  }
  &__deco {
  position: absolute;
  z-index: -1;
  width: 436px;
  height: 436px;
  top: 100px;
  right: -96px;
  pointer-events: none;
  @include mq(sp) {
    width: 143px;
    height: 218px;
    top: 120px;
    right: -18px;
  }
  svg {
    fill: palettes(main, lightest);
  }
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
    &.-nobg {
      background: none;
      padding-top: 80px;
      padding-bottom: 110px;
    }
  }
  .c-copy-basic {
    margin: 0 0 60px;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 60px;
    @include mq(sp) {
      text-align: left;
      font-size: 1.5rem;
      padding: 30px 15px;
      margin: 0;
    }
  }
  &__content {
    .c-box-basic {
      flex-direction: row-reverse;
      align-items: flex-start;
      margin: 0 0 64px;
      @include mq(sp) {
        margin: 0;
        &:nth-child(n+2) {
          margin: 40px 0 0;
        }
      }
      &.-rev {
        @include mq(pc) {
          flex-direction: row;
          .c-box-basic__textarea {
            &:after {
              left: auto;
              right: 0;
            }
          }
          .c-box-basic__img {
            margin: 0 0 0 -50px;
          }
        }
        @include mq(sp) {
          .c-box-basic__img {
            margin: 0;
          }
        }
      }
      &__textarea {
        background-color: #fff;
        padding: 60px;
        margin-top: 60px;
        position: relative;
        flex: 1 1 auto;
        @include mq(pc) {
          min-height: 380px;
        }
        @include mq(sp){
          padding: 0;
          margin-top: 0;
          height: auto;
        }
        &:after {
          content: "";
          display: block;
          width: 610px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #fff;
          z-index: -1;
          @include mq(sp){
            display: none;
          }
        }
        @include mq(sp) {
          padding: 20px;
        }
        &:before {
          display: none;
        }
      }
      &__img {
        width: 560px;
        z-index: 1;
        margin: 0 -50px 0 0;
        &.-noshift {
          width: 400px;
          margin: 0;
          @include mq(sp) {
          width: 100%;
          }
        }
        @include mq(sp) {
          width: auto;
          margin: 0;
        }
        &::before {
          content: "";
          display: block;
          padding: 67.85% 0 0;
        }
        &.-noshift {
          &::before {
            padding: 110% 0 0 0;
          }
        }
        figure {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 0;
          background: transparent center center no-repeat;
          background-size: cover;
          &::before {
            content: "";
            display: block;
            padding: 67.85% 0 0;
          }
        }
        img {
          display: none;
        }
      }
    }
    .c-box {
      margin-bottom: 80px;
      position: relative;
      &.-nmb {
      margin-bottom: 0;
      }
      @include mq(sp) {
      margin-bottom: 40px;
      }
      &__img {
      width: 440px;
      height: 240px;
      position: absolute;
      right: 0;
      top: 0;
      flex: 0 0 auto;
      overflow: hidden;
      &:before {
        content: "";
        display: block;
        padding: 54.54% 0 0;
      }
      @include mq(sp) {
        width: 100%;
        height: auto;
        position: relative;
      }
      a {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        figure {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent center center no-repeat;
        background-size: cover;
        transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
        &:before {
          content: "";
          display: block;
          padding: 54.54% 0 0;
        }
        img {
          display: none;
        }
        }
      }
      }
      &.-has-img {
      margin-bottom: 55px;
      .c-desc {
        width: calc(100% - 500px);
        @include mq(sp) {
        width: 100%;
        margin-bottom: 20px;
        }
      }
      }
    }

    .c-btn.-lines {
      height: 60px;
      width: 100%;
      a {
      font-size: 1.4rem;
      line-height: 1.4;
      text-align: center;
      }
    }
    .c-box-detail {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
      }
      &__textarea {
      width: calc(100% - 440px);
      padding-left: 60px;

      @include mq(sp) {
        width: 100%;
        padding-left: 0;
      }
      .c-copy {
        font-size: 2.2rem;
        letter-spacing: 0.1em;
        margin-bottom: 20px;
        @include mq(sp) {
        font-size: 1.8rem;
        margin-bottom: 10px;
        }
      }
      }
      &.-rev {
      flex-direction: row-reverse;
      .c-box-detail__textarea {
        padding-left: 0;
        padding-right: 60px;
        @include mq(sp) {
        padding-right: 0;
        }
      }
      }
      &__img {
      width: 440px;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 20px;
      }
      }
    }
  }
}

/* ========
list-gift
======== */
.o-list-gift {
  background-color: #fff;
  padding-bottom: 60px;
  &__header {
  padding: 0 60px 40px 60px;
  }
  &__footer {
  display: flex;
  justify-content: center;
  @include mq(sp){
    padding-left: 20px;
    padding-right: 20px;
  }
  }
  &__item {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 40px;
  @include mq(sp){
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  &.-hide{
    display: none;
  }
  &__img {
    width: 400px;
    @include mq(sp){
      width: 100%;
      padding:0 20px;
    }
    .-note {
      font-size: 1.2rem;
      text-align: center;
    }
    figure{
      border: 1px solid rgba(palettes(main,light),.5);
    }
  }
  &__textarea {
    width: calc(100% - 400px);
    padding-left: 40px;
    @include mq(sp){
    width: 100%;
    padding:20px;
    }
    .c-ttl {
    font-size: 2.2rem;
    line-height: 1.25;
    margin-bottom: 20px;
    }
    .c-desc {
    margin-bottom: 15px;
    }
  }
  &__meta {
    .label-list {
    display: flex;
    margin-bottom: 25px;
    li {
      border-radius: 3px;
      background-color: #3c786e;
      color: #fff;
      font-size: 1.4rem;
      line-height: 20px;
      height: 20px;
      padding-left: 10px;
      padding-right: 10px;
      &:not(:last-child) {
      margin-right: 2px;
      }
    }
    }
    .c-desc {
    line-height: 1.5;
    margin-bottom: 15px;
    .-main {
      display: block;
    }
    }
    .-appl-no {
    display: flex;
    font-size: 1.4rem;
    color: $color_main;
    line-height: 24px;
    margin-bottom: 4px;
    .-hd {
      border: 1px solid $color_main;
      background-color: $color_main;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
    }
    .-no {
      border: 1px solid $color_main;
      color: $color_main;
      padding-left: 15px;
      padding-right: 15px;
    }
    .-opt {
      color: $color_text;
      padding-left: 10px;
    }
    }
    .-price {
    font-size: 2rem;
    .-unit {
      font-size: 1.4rem;
    }
    }
  }
  }
  .btn-sort {
  width: 140px;
  height: 40px;
  color: $color_main;
  margin: 0 auto;
  height: 40px;
  position: relative;
  .accordionBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid $color_main;
    padding-right: 12px;
    cursor: pointer;
    &::after {
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    right: 15px;
    top: calc(50% - 4px);
    pointer-events: none;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: $color_main transparent transparent transparent;
    opacity: 0.8;
    }
  }
  ul {
    display: none;
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 6.82px 7.314px 30px 0px rgba(4, 0, 0, 0.05);
    li {
    text-align: center;
    height: 40px;
    &:not(:last-child){
      border-bottom: 1px solid #e3ddd7;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    }
  }
  }
  .c-btn.-ghost.-loadmore{
  height: 36px;
  width: 470px;
  a{
    border-left: 0;
    border-right: 0;
  }
  }
}

/* ========
feat-gift
======== */
.o-feat-gift{
  background-color: palettes(main, lightest);
  padding: 80px 0;
  @include mq(sp){
    padding: 15px;
  }
  .l-inner{
    width: 1000px;
    background-color: #fff;
    padding: 60px;
    @include mq(sp){
      width: 100%;
      padding: 20px;
    }
    .c-copy-basic{
      font-size: 2.8rem;
      margin-bottom: 30px;
      text-align: center;
    }
    .c-box-img-types{
      &:not(:last-child){
        margin-bottom: 40px;
      }
      &__textarea__inner{
        padding: 30px;
        border: 1px solid $color_main;
        @include mq(pc) {
          display: flex;
          justify-content: space-between;
          > * {
            width: calc(50% - 12px);
          }
        }
        .c-desc{
          @include mq(sp) {
            margin-bottom: 20px;
          }
        }
        @include mq(sp){
          padding: 15px;
        }
        .c-btn{
          width: 100%;
          height: 64px;
          &:not(:last-child){
            margin-bottom: 2px;
          }
          a{
            font-size: 1.3rem;
            line-height: 1.5;
          }
        }
      }
    }
    .c-box-img-types__img{
      width: 50%;
      @include mq(sp){
        width: 100%;
      }
      &:before{
        padding-top: 60%;
      }
      &.-has-caption{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &:before{
          padding-top: 67%;
        }
        figure{
          width: 45%;
        }
        .fig-caption{
          width: 55%;
          margin-left: auto;
          padding-left: 20px;
          position: relative;
          @include mq(sp){
            padding-bottom: 13%;
          }
          figure{
            width: 180px;
            height: 60px;
            top: auto;
            bottom: 0;
            left: auto;
            right: 0;
            @include mq(sp){
              // position: static;
              width: 80%;
              height: auto;
              padding-top: 26%;
            }
          }
        }
      }
    }
  }
}