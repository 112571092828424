//-----------------------
.o-remake-example {
  background-color: palettes(main,lightest);
  padding: 0 0 100px;
  .l-inner {
    background-color: #fff;
    width: 1000px;
    @include mq(sp) {
      width: 100%;
    }
  }
  &__head {
    padding: 88px 0 48px;
    @include mq(sp) {
      padding: 64px 0 40px;
    }
  }
  &__hero {
    position: relative;
    z-index: 1;
    figure {
      width: 100%;
      height: 360px;
      display: block;
      @include mq(sp) {
        width: 100%;
        height: 200px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &__cards {
    display: flex;
    justify-content: space-between;
    counter-reset: cards;
    position: relative;
    z-index: 0;
    margin: -140px 56px 0;
    @include mq(sp) {
      flex-wrap: wrap;
      margin: -60px 20px 0;
    }
    li {
      width: 205px;
      padding: 20px 30px 48px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      background: linear-gradient(180deg, transparent 0%, transparent 40px, #eae6e2 40px, #eae6e2 100%);
      @include mq(sp) {
        width: calc(50% - 5px);
        padding: 20px 20px 30px;
        margin: 10px 0 0;
        background: linear-gradient(180deg, transparent 0%, transparent 20px, #eae6e2 20px, #eae6e2 100%);
      }
      &:nth-child(2n) {
        background: linear-gradient(180deg, transparent 0%, transparent 40px, palettes(blue,lightest) 40px, palettes(blue,lightest) 100%);
        @include mq(sp) {
          background: linear-gradient(180deg, transparent 0%, transparent 20px, palettes(blue,lightest) 20px, palettes(blue,lightest) 100%);
        }
        &:after {
          background-color: palettes(blue,lightest);
        }
      }
      &:before {
        counter-increment: cards 1;
        content: counter;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        left: 40px;
        bottom: 0;
        background-color: #eae6e2;
        z-index: -1;
        transform: skew(-45deg);
        transform-origin: left top;
        pointer-events: none;
        @include mq(sp) {
          left: 20px;
        }
      }
    }
    &__num {
      font-family: $cinzelD;
      color: palettes(main);
      font-size: 1.8rem;
      font-weight: 400;
      letter-spacing: .15em;
      text-align: right;
      line-height: 1;
      margin: 0 0 32px;
      @include mq(sp) {
        font-size: 1.6rem;
        margin: 0 0 20px;
      }
      span {
        font-size: 3.4rem;
        @include mq(sp) {
          font-size: 2.6rem;
        }
      }
    }
    &__desc {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.9;
      text-align: justify;
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 0;
    padding: 0 56px 96px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(sp) {
      padding: 0 15px 64px;
    }
    .o-unit-case {
      margin: 80px 0 0;
      @include mq(sp) {
        margin: 56px 0 0;
      }
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    // top: 80px;
    top: 160px;
    right: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 120px;
      right: -8px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
}