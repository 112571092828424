//-----------------------
.c-box-report{
  a,.c-box-report__main{
    display: flex;
    @include mq(sp){
      display: block;
      background: #fff;
      padding: 32px 20px;
    }
  }
  a{
    @include mq(pc) {
      &:hover{
        .c-box-report__img{
          figure{
            transform: scale(1.02);
          }
        }
      }
    }
  }
  &__img{
    z-index: 1;
    flex: 0 0 auto;
    overflow: hidden;
    @include mq(pc){
      width: calc(50% + 50px);
      transform: translate(0, -50px);
    }
    @include mq(sp){
      width: 100%;
      position: relative;
      padding: 70% 0 0;
    }
    figure {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url(image.png) center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
    }
    img {
      display: none;
    }
  }
  &__textarea{
    position: relative;
    padding: 88px 80px 88px 140px;
    z-index: 0;
    @include mq(sp) {
      padding: 0;
    }
    &:after{
      content: '';
      display: block;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      pointer-events: none;
      @include mq(pc){
        left: -274px;
        right: -50px;
      }
      @include mq(sp){
        right: 0;
      }
    }
    .c-box-report__date{
      font-family: $cormorant;
      font-size: 2rem;
      letter-spacing: .1em;
      color: $color_main;
      span{
        font-size: 2.6rem;
        padding-left: 8px;
      }
    }
    .c-box-report__ttl{
      font-family: $cinzelD;
      font-size: 3.2rem;
      line-height: 3.3;
      letter-spacing: .1em;
      color: $color_main;
      span{
        padding: 0 10px;
      }
      @include mq(sp){
        font-size: 2.8rem;
        line-height: 1.07;
      }
    }
    .c-box-report__subttl{
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      line-height: 2.1;
      letter-spacing: .02em;
      @include mq(sp){
        font-size: 1.4rem;
        margin-top: 8px;
      }
      .-surname{
        padding-left: 4px;
      }
      .-dots{
        padding: 0 8px;
      }
      &:before{
        content: "";
        display: block;
        width: 14px;
        height: 1px;
        background: $color_main;
        margin-right: 12px;
        @include mq(sp){
          margin-right: 8px;
        }
      }
    }
    .c-list-definition{
      margin-top: 40px;
      @include mq(sp){
        margin: 24px 0;
      }
    }
    .c-btn{
      @include mq(pc){
        position: absolute;
        right: 10px;
        bottom: -27px;
      }
      @include mq(sp){
        margin: 0 auto;
      }
    }
  }
  &__phbox{
    position: relative;
    z-index: 0;
    figure{
      background: transparent center center no-repeat;
      background-size: cover;
      &:nth-child(1){
        width: 450px;
        height: 310px;
        margin: 0 0 2px;
        @include mq(sp) {
          width: 100%;
          height: 260px;
        }
      }
      &:nth-child(2){
        width: 220px;
        height: 190px;
        margin: 0 0 2px;
        @include mq(sp) {
          width: calc(50% - 1px);
          height: 150px;
        }
      }
      &:nth-child(3){
        width: 220px;
        height: 190px;
        @include mq(sp) {
          width: calc(50% - 1px);
          height: 150px;
        }
      }
      &:nth-child(4){
        width: 228px;
        height: 382px;
        margin: -382px 0 2px auto;
        @include mq(sp) {
          width: calc(50% - 1px);
          height: 302px;
          margin-top: -302px;
        }
      }
      &:nth-child(5){
        width: 450px;
        height: 320px;
        @include mq(sp) {
          width: 100%;
          height: 250px;
        }
      }
      img{
        display: none;
      }
    }
  }
}