//-----------------------
.c-box-information{
  a{
    display: flex;
    flex-direction: row-reverse;
    @include mq(sp){
      display: block;
    }
    @include mq(pc) {
      &:hover{
        .c-box-information__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
  }
  &__img{
    z-index: 1;
    flex: 0 0 auto;
    overflow: hidden;
    @include mq(pc){
      width: calc(50% + 50px);
      transform: translate(0, 50px);
    }
    @include mq(sp){
      width: 100%;
      position: relative;
      padding: 70% 0 0;
    }
    figure {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparent url(image.png) center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
    }
    img {
      display: none;
    }
  }
  &__textarea{
    position: relative;
    padding: 84px 0px 50px 50px;
    margin-top: 0;
    z-index: 0;
    @include mq(sp) {
      padding: 74px 30px 45px;
    }
    &:after{
      content: '';
      display: block;
      background-color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: -90px;
      z-index: -1;
      pointer-events: none;
      @include mq(sp){
        right: 0;
      }
    }
  }
  &__label{
    width: 350px;
    height: 44px;
    background: $color_blue;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: -20px;
    @include mq(sp){
      width: 264px;
      height: 36px;
    }
    &:before,&:after{
      content: '';
      display: block;
      position: absolute;
      right: -11px;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:before{
      border-width: 22px 11px 0 0;
      border-color: $color_blue transparent transparent transparent;
      top: 0;
    }
    &:after{
      border-width: 22px 0 0 11px;
      border-color: transparent transparent transparent $color_blue;
      bottom: 0;
    }
    span{
      font-family: $cinzelD;
      font-size: 1.8rem;
      line-height: 3.3;
      letter-spacing: .12em;
      color: #fff;
      @include mq(sp){
        font-size: 1.4rem;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 10px 0;
        border-color: transparent palettes(blue,darkest) transparent transparent;
      }
    }
  }
  &__date{
    display: flex;
    align-items: center;
    &__main{
      margin: 0 16px 0 0;
      .-date{
        display: block;
        font-family: $cormorant;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: .1em;
        line-height: 1;
      }
    }
    &__small{
      font-family: $roboto;
      line-height: 1.4;
      span{
        display: block;
      }
      .-year{
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: .05em;
      }
      .-day{
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: .1em;
      }
    }
  }
  &__ttl{
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.2;
    border-bottom: 1px solid #000;
    padding: 0 30px .75em 0;
    margin: 12px 0 24px;
    @include mq(sp){
      font-size: 1.6rem;
      padding: 0 0 .75em;
    }
  }
  .c-desc{
    font-size: 1.5rem;
    line-height: 1.8;
    padding-right: 30px;
    @include mq(sp){
      font-size: 1.3rem;
      padding-right: 0;
    }
  }
  .c-btn{
    margin-top: 36px;
    @include mq(sp){
      margin: 36px auto 0;
    }
  }
}