//-----------------------
.c-box-photo-desc{
  background: #fff;
  padding: 45px 40px;
  @include mq(sp){
    padding: 30px 20px;
  }
  .c-ttl-item{
    font-size: 2.8rem;
    letter-spacing: .1em;
  }
  .c-copy-basic{
    font-size: 2.2rem;
  }
  .c-box-photo-desc__img{
    margin-top: 10px;
  }
  .c-desc{
    margin-top: 28px;
    p{
      &:nth-child(n+2){
        margin-top: 1em;
      }
    }
  }
}