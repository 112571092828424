//-----------------------

.c-list-definition{
  &__item{
    width: 100%;
    display: flex;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    @include mq(sp){
      font-size: 1.3rem;
    }
    .c-list-definition__ttl{
      flex: 0 0 auto;
      color: $color_main;
      position: relative;
      min-width: 60px;
      &:after{
        content: '';
        display: block;
        width: 1px;
        height: 14px;
        background: $color_main;
        position: absolute;
        top: 7px;
        right: 0;
      }
    }
    .c-list-definition__txt,.c-list-definition__txt>span{
      flex: 0 1 auto;
      margin-left: 20px;
      font-size: 1.4rem;
      letter-spacing: .2em;
      line-height: 2;
      @include mq(sp){
        font-size: 1.3rem;
      }
    }
    .c-list-definition__txt>span{
      margin-left: 0;
    }
  }
}