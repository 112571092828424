//-----------------------
.c-box-fair{
  &__ttl{
    font-size: 2.2rem;
    letter-spacing: .02em;
    color: $color_text;
    width: 100%;
    border: solid $color_main;
    border-width: 1px 0;
    margin: 0 0 26px;
    @include mq(sp){
      font-size: 1.8rem;
      margin: 0 0 16px;
    }
  }
  .c-desc{
    letter-spacing: 0;
    text-align: center;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 30px 0 24px;
    @include mq(sp){
      margin: 20px 0;
    }
  }
  &__item{
    width: calc((100% - 8px)/3);
    flex: 0 0 auto;
    margin-right: 4px;
    &:nth-of-type(3n){
      margin-right: 0;
    }
    &:nth-of-type(n+4){
      margin-top: 4px;
    }
    label{
      p{
        width: 100%;
        height: 100%;
        display: block;
        font-size: 1.4rem;
        letter-spacing: .05em;
        line-height: 1.6;
        color: $color_text;
        padding: 20px 0;
        background: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        transition: .4s ease;
        @include mq(sp){
          font-size: 1.3rem;
          padding: 12px 0;
        }
      }
      input{
        display: none;
        &:checked + p{
          background: palettes(beige,light);
          border: 1px solid palettes(beige,dark);
        }
      }
    }
    &:hover{
      p{
        background: rgba(#fff,.5);
        border: 1px solid rgba(palettes(beige,dark),.5);
      }
    }
  }
}