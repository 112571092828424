.c-lp-caption {
  width: 220px;
  &__img {
    width: 100%;
    border: 4px solid #fff;
    margin: 0 0 12px;
    // aspect-ratio: 220 / 165;
    @include aspect-ratio(220, 165);
    img {
      object-fit: cover;
      object-position: center center;
    }
  }
  &__copy {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.9;
    padding: 0 0 .6em;
    margin: 0 0 .8em;
    border-bottom: 1px solid currentColor;
  }
  &__desc {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.9;
    text-align: justify;
    word-break: break-all;
  }
}