.c-lp-fixed-btn {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 54px;
  height: 178px;
  z-index: 999;
  margin: auto 0;
  @include mq(sp) {
    bottom: auto;
    margin: 0;
    width: 80px;
    height: 80px;
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: palettes(blue);
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .075em;
    @include mq(pc) {
      transition: background-color .4s ease;
      &:hover {
        background-color: darken(palettes(blue),6%);
      }
    }
    @include mq(sp) {
      font-size: 1rem;
      padding: .5em 0 0;
    }
    .iconSvg {
      width: 21px;
      height: 16px;
      margin: 0 0 .8em;
      @include mq(sp) {
        width: 30px;
        height: 23px;
        margin: 0 0 .4em;
      }
      svg {
        fill: currentColor;
      }
    }
    span {
      display: block;
      @include mq(pc) {
        writing-mode: vertical-rl;
      }
    }
  }
}