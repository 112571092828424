//-----------------------
.c-text-copyright{
  display: block;
  font-family: $cormorant;
  font-weight: 400;
  letter-spacing: .1em;
  font-size: 1.2rem;
  text-align: center;
  text-shadow: 0 0 0 currentColor;
  margin: 40px 0 0;
  @include mq(sp) {
    text-align: left;
    margin: 32px 0 0;
  }
}