/* ========
intro-bridesroom
======== */

.o-intro-bridesroom{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  padding-bottom: 120px;
  @include mq(sp){
    padding-bottom: 64px;
  }
  .o-hero-caption{
    @include mq(pc){
      .l-inner{
        padding: 0;
        &:before{
          bottom: -140px;
        }
      }
      &__textarea{
        padding: 72px 0 0;
        .c-ttl-section{
          margin: 0 0 40px;
        }
      }
    }
  }
  &__content{
    .l-inner{
      max-width: 1000px;
      @include mq(sp){
        width: calc(100% - 40px);
      }
    }
    .c-box-images{
      display: block;
      @include mq(sp){
        margin-top: 64px;
      }
      &__img{
        width: 100%;
        margin-top: 80px;
        @include mq(sp){
          margin-top: 0;
        }
        &__main{
          @include mq(pc){
            width: 916px;
            height: 400px;
          }
        }
      }
      &__textarea{
        width: 720px;
        margin-left: auto;
        margin-top: -80px;
        background: #fff;
        padding: 132px 56px 48px 106px;
        @include mq(sp){
          width: calc(100% + 40px);
          padding: 80px 20px 30px;
          margin: -50px -20px 0;
        }
        &:before,&:after{
          content: "";
          display: block;
          width: 310px;
          height: 190px;
          z-index: 1;
          position: absolute;
        }
        &:before{
          background: url(../images/ceremony/bridesroom/img_bridesroom02.jpg) center center no-repeat;
          background-size: cover;
          top: 110px;
          left: -240px;
          @include mq(sp){
            display: none;
          }
        }
        &:after{
          background: url(../images/ceremony/bridesroom/img_bridesroom03.jpg) center center no-repeat;
          background-size: cover;
          top: 328px;
          left: -280px;
          @include mq(sp){
            display: none;
          }
        }
      }
      &__ttlarea{
        display: flex;
        align-items: center;
        .c-ttl-sub{
          margin-left: 16px;
        }
        @include mq(sp){
          flex-wrap: wrap;
          .c-ttl-sub{
            margin-left: 0;
            margin-top: 5px;
          }
        }
      }
      &.-rev{
        .c-box-images{
          &__img{
            &__main{
              margin-left: auto;
            }
          }
          &__textarea{
            @include mq(pc){
              margin-left: 0;
              padding: 132px 108px 60px 60px;
            }
            &:before{
              background: url(../images/ceremony/bridesroom/img_bridesroom05.jpg) center center no-repeat;
              background-size: cover;
              top: 110px;
              left: calc(100% - 30px);
              @include mq(sp){
                display: none;
              }
            }
            &:after{
              background: url(../images/ceremony/bridesroom/img_bridesroom06.jpg) center center no-repeat;
              background-size: cover;
              top: 328px;
              left: calc(100% - 70px);
              @include mq(sp){
                display: none;
              }
            }
          }
        }
      }
      &.-simple{
        .c-box-images{
          &__img{
            margin-top: 100px;
            @include mq(sp){
              margin-top: 0;
            }
            &__main{
              width: 440px;
              height: 460px;
              @include mq(sp){
                width: 100%;
                height: 230px;
              }
            }
          }
          &__textarea{
            @include mq(pc){
              width: 620px;
              margin-top: -410px;
              padding: 60px 54px 60px 120px;
            }
            &:before,&:after{
              display: none;
            }
          }
        }
      }
    }
  }
}
