//-----------------------
.c-box-pickup{
  // large-static
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  // middle-static
  &.-middle{
    width: 240px;
    transform: none;
    right: 20px;
    bottom: 20px;
    border-width: 2px;
    @include mq(sp) {
      width: 160px;
      right: 10px;
      bottom: 10px;
    }
    .c-box-pickup{
      &__close{
        width: 24px;
        height: 24px;
        top: -2px;
        left: -2px;
        &:before, &:after{
          width: 16px;
        }
      }
      &__img{
        height: 160px;
        @include mq(sp) {
          height: 100px;
        }
      }
      &__textarea{
        padding: 16px;
      }
      &__schedule{
        .-date{
          font-size: 2.1rem;
        }
        .-time{
          font-size: 1.2rem;
        }
      }
      &__ttl{
        font-size: 1.4rem;
        padding: 8px 0 0;
        margin: 8px 0 0;
        @include mq(sp) {
          font-size: 1.2rem;
          padding: 0;
          margin: 0;
        }
      }
    }
    a{
      &:after{
        content: none;
      }
    }
  }
  width: 478px;
  border: 4px solid palettes(main);
  background-color: palettes(main);
  &__close{
    width: 40px;
    height: 40px;
    background-color: palettes(main);
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: 1;
    cursor: pointer;
    &:before, &:after{
      content: '';
      display: block;
      width: 24px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
  a{
    display: block;
    width: 100%;
    // overflow: hidden;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      transition: .4s ease;
      &:hover{
        opacity: .6;
      }
    }
    &:after{
      content: 'MORE';
      width: 92px;
      height: 30px;
      background-color: palettes(main);
      color: #fff;
      font-family: $cormorant;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .1em;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -4px;
      margin: 0 auto;
    }
  }
  &__schedule{
    line-height: 1;
    .-date{
      font-family: $cormorant;
      font-size: 2.8rem;
      font-weight: 400;
      letter-spacing: .2em;
      line-height: 1;
      margin: 0 .5em 0 0;
    }
    .-time{
      font-family: $roboto;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .05em;
      line-height: 1;
    }
  }
  &__img{
    width: 100%;
    background: transparent center center no-repeat;
    background-size: cover;
    height: 320px;
    img{
      display: none;
    }
  }
  &__textarea{
    padding: 24px 32px 40px;
    background-color: #fff;
  }
  &__ttl{
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .1em;
    line-height: 1.6;
    border-top: 1px solid palettes(main,light);
    padding: 16px 0 0;
    margin: 16px 0 0;
  }
  &__desc{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    text-align: justify;
    line-height: 1.8;
  }


  
  @include mq(sp) {
    width: 120px;
    transform: none;
    right: 20px;
    bottom: 20px;
    border-width: 2px;
    .c-box-pickup{
      &__close{
        width: 24px;
        height: 24px;
        top: -2px;
        left: -2px;
        &:before, &:after{
          width: 16px;
        }
      }
      &__img{
        height: 80px;
      }
      &__textarea{
        padding: 12px;
      }
      &__schedule{
        display: none;
        .-date{
          font-size: 2.1rem;
        }
        .-time{
          font-size: 1.2rem;
        }
      }
      &__ttl{
        font-size: 1.2rem;
        padding: 0;
        margin: 0;
        border: none;
      }
    }
    a{
      &:after{
        content: none;
      }
    }
  }
}