/* ========
intro-concept
======== */

.o-intro-concept{
  background: palettes(main,lightest);
  padding-bottom: 120px;
  @include mq(sp){
    padding-bottom: 80px;
  }
  @include mq(pc){
    .o-hero-simple{
      height: 260px;
    }
  }
  .l-inner{
    max-width: 1000px;
  }
  .o-intro-concept__mv{
    margin-top: -50px;
    @include mq(sp){
      margin-top: 0;
    }
  }
  .o-intro-concept__desc{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    @include mq(sp){
      flex-wrap: wrap;
      width: calc(100% - 40px);
      margin: 20px auto 0;
    }
    .c-box-basic__textarea{
      width: 600px;
      background: #fff;
      text-align: center;
      padding: 52px 0;
      margin-left: 10px;
      @include mq(sp){
        width: auto;
        padding: 24px 30px;
        margin: 0;
      }
      .c-copy-basic{
        font-size: 2.4rem;
        line-height: 1.9;
        @include mq(sp){
          font-size: 1.6rem;
        }
      }
      .c-desc{
        text-align: center;
        margin-top: 32px;
        @include mq(sp) {
          text-align: left;
        }
      }
    }
    .o-intro-concept__img{
      .bgImg{
        &:nth-child(2){
          margin-top: 10px;
        }
        @include mq(sp){
          margin-top: 10px;
        }
      }
    }
  }
  .o-intro-concept__ph{
    margin-top: 10px;
    @include mq(sp){
      width: calc(100% - 40px);
      margin: 10px auto 0;
    }
    &01{
      display: flex;
      @include mq(sp){
        flex-wrap: wrap;
      }
      .bgImg{
        &:nth-child(2){
        margin-left: 10px;
        @include mq(sp){
          margin: 10px 0 0 0;
        }
        }
      }
    }
    &02{
      margin-top: 10px;
    }
  }
}