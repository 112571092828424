/* ========
top
======== */

.home{
  .l-wrapper{
    padding: 0;
    @include mq(sp) {
      padding: 0;
    }
  }
}