//-----------------------
.c-box-simple{
  position: relative;
  z-index: 0;
  width: 300px;
  @include mq(sp) {
    width: 100%;
  }
  a{
    display: block;
    @include mq(pc) {
      &:hover{
        .c-box-simple__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    &:before{
      content: '';
      display: block;
      top: -10px;
      left: 50%;
      right: -10px;
      bottom: -10px;
      position: absolute;
      z-index: -1;
      background-color: palettes(main,light);
    }
  }
  &__img{
    width: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &:before{
      content: '';
      display: block;
      padding: 73.33% 0 0;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
    img{
      display: none;
    }
  }
  &__textarea{
    background-color: #fff;
    padding: 20px 20px 56px;
    min-height: 220px;
    @include mq(sp) {
      min-height: 0;
    }
  }
  &__logo{
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px;
    img{
      width: auto;
      height: 100%;
    }
  }
  .c-ttl-item{
    font-size: 2.8rem;
    letter-spacing: .06em;
    line-height: 1.32;
    margin: 0 0 4px;
  }
  &__more{
    font-family: $cormorant;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .05em;
    color: palettes(main);
    line-height: 1.4;
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 16px;
    .iconSvg{
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 0 0 .5em;
    }
    svg{
      fill: palettes(main);
    }
  }
}