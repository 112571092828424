//-----------------------
.c-btn-icon{
  width: 180px;
  height: 54px;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: palettes(main);
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .05em;
    position: relative;
    z-index: 0;
    padding: 0 0 0 24px;
  }
  .iconSvg{
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    position: absolute;
    top: 0;
    left: 16px;
    bottom: 0;
    margin: auto 0;
    svg{
      fill: #fff;
    }
  }

  // color
  &.-blue{
    a{
      background-color: palettes(blue);
    }
  }
  &.-pink{
    a{
      background-color: palettes(pink);
    }
  }
  &.-black{
    a{
      background-color: #323232;
    }
  }
}