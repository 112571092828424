/* ========
intro-reception
======== */

.o-intro-reception{
  background-color: palettes(main,lightest);
  padding: 0 0 152px;
  @include mq(sp) {
    padding: 0 0 64px;
  }
  .l-inner{
    width: 1000px;
    background-color: #fff;
    margin-top: -80px;
    padding: 56px 0 0;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      margin-top: -40px;
      width: calc(100% - 40px);
      padding: 40px 0 0;
    }
    > [id]{
      padding: 124px 0 0;
      margin: -124px 0 0;
      @include mq(sp) {
        padding: 64px 0 0;
        margin: -64px 0 0;
      }
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    top: 220px;
    right: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 140px;
      right: -8px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  &__head{
    padding: 0 0 72px;
    @include mq(sp) {
      padding: 0 20px 56px;
    }
    .c-ttl-section{
      margin: 0 0 40px;
    }
    .c-copy-basic{
      margin: 0 0 24px;
      text-align: center;
      @include mq(sp) {
        text-align: left;
      }
    }
    .c-desc{
      text-align: center;
      @include mq(sp) {
        text-align: justify;
      }
    }
  }
}