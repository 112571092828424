//-----------------------
.c-badge-pickup{
  position: absolute;
  z-index: 0;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  img{
    pointer-events: none;
  }
}