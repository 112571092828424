/* ========
intro-owl
======== */

.o-intro-owl {
  background-color: palettes(main, lightest);
  padding: 0 0 152px;
  @include mq(sp) {
    padding: 0 0 64px;
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
    &.-nobg {
      background: none;
      padding-bottom: 40px;
    }
  }
  .l-inner {
    width: 1000px;
    @include mq(sp) {
      width: calc(100% - 40px);
      padding: 0;
    }
    & > .c-copy {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    @include mq(sp) {
      display: block;
    }
    &__img {
      width: 63%;
      // height: 740px;
      position: relative;
      z-index: 0;
      margin-left: auto;
      &:before {
        content: "";
        display: block;
        padding: 117.46% 0 0;
      }
      @include mq(sp) {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
      figure {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent center center no-repeat;
        background-size: cover;
        &:before {
          content: "";
          display: block;
          padding: 117.46% 0 0;
        }
        img {
          display: none;
        }
      }
    }
    &__textarea {
      width: 36%;
      position: relative;
      z-index: 0;
      @include mq(sp) {
        width: 100%;
      }
      &__img {
        position: relative;
        z-index: 0;
        margin-bottom: 10px;
        &:before {
          content: "";
          display: block;
          padding: 66.66% 0 0;
        }
        figure {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent center center no-repeat;
          background-size: cover;
          height: 240px;
          @include mq(sp) {
            height: auto;
          }
          &:before {
            content: "";
            display: block;
            padding: 66.66% 0 0;
          }
          img {
            display: none;
          }
        }
      }
      &__inner {
        // height: 490px;
        padding: 40px 50px;
        background-color: #fff;
        @include mq(sp) {
          height: auto;
          padding: 20px;
        }
        .c-desc {
          margin-bottom: 40px;
          @include mq(sp) {
            margin-bottom: 20px;
          }
        }
        .c-btn {
          margin: 0 auto;
        }
      }
    }
  }
  &__img-grid {
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    &:before {
      content: "";
      display: block;
      padding-top: 56%;
    }
    figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      &:before {
        content: "";
        display: block;
      }
      &:nth-of-type(1) {
        height: 330px;
        width: 64%;
        right: 27%;
        bottom: 41.07%;
        &:before {
          padding-top: 51.56%;
        }
      }
      &:nth-of-type(2) {
        width: 27%;
        height: 220px;
        top: 60.71%;
        &:before {
          padding-top: 81.48%;
        }
      }
      &:nth-of-type(3) {
        width: 36%;
        height: 220px;
        top: 60.71%;
        left: 28%;
        &:before {
          padding-top: 61.11%;
        }
      }
      &:nth-of-type(4) {
        width: 35%;
        height: 560px;
        left: 65%;
        &:before {
          padding-top: 160%;
        }
      }
      @include mq(sp) {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          height: auto;
        }
      }
      img {
        display: none;
      }
    }
  }
  &__movie {
    background-color: #fff;
    a {
      display: block;
      width: 100%;
      padding: 60px;
      @include mq(pc) {
        &:hover {
          .o-intro-owl__movie__mov {
            figure {
              transform: scale(1.06);
            }
          }
        }
      }
      @include mq(sp) {
        padding: 20px;
      }
    }
    &__mov {
      margin-bottom: 40px;
      position: relative;
      z-index: 0;
      width: 100%;
      overflow: hidden;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      &:before {
        content: "";
        display: block;
        padding: 54.32% 0 0;
      }
      figure {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent center center no-repeat;
        background-size: cover;
        transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
        &:before {
          content: "";
          display: block;
          padding: 54.32% 0 0;
        }
        img {
          display: none;
        }
      }
    }
  }
}
