//-----------------------
.c-ttl-section{
  text-align: center;
  .iconSvg{
    display: inline-block;
    width: 42px;
    height: 42px;
    @include mq(sp) {
      width: 34px;
      height: 34px;
    }
    
    &[data-icon="instagram"]{
      width: 28px;
      height: 28px;
    }

    svg{
      fill: palettes(main);
    }
  }
  &__en{
    font-family: $cinzelD;
    font-weight: 400;
    letter-spacing: .15em;
    font-size: 4rem;
    color: palettes(main,dark);
    line-height: 1.4;
    @include mq(sp) {
      font-size: 3rem;
    }
  }
  &__ja{
    font-size: 1.4rem;
    letter-spacing: .02em;
    font-weight: 500;
    @include mq(sp) {
      display: block;
      margin: -.3em 0 0;
    }
  }
}
