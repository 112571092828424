//-----------------------
.c-bg-deco01{
  $bg: darken(palettes(main,lightest),5%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bg;
  pointer-events: none;
  z-index: -1;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border: 1px solid #fff;
    z-index: 1;
    @include mq(sp) {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
  &__inner{
    &:before,&:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    &:before{
      // white border
      background: transparent no-repeat;
      background-size: 50px 50px;
      background-image:
        radial-gradient(circle at top left, $bg 48px, #fff 49px, transparent 50px),
        radial-gradient(circle at top right, $bg 48px, #fff 49px, transparent 50px),
        radial-gradient(circle at bottom right, $bg 48px, #fff 49px, transparent 50px),
        radial-gradient(circle at bottom left, $bg 48px, #fff 49px, transparent 50px),
      ;
      background-position:
        top -20px left -20px,
        top -20px right -20px,
        bottom -20px right -20px,
        bottom -20px left -20px,
      ;
      border: 20px solid $bg;
      @include mq(sp) {
        background-size: 25px 25px;
        background-image:
          radial-gradient(circle at top left, $bg 23px, #fff 24px, transparent 25px),
          radial-gradient(circle at top right, $bg 23px, #fff 24px, transparent 25px),
          radial-gradient(circle at bottom right, $bg 23px, #fff 24px, transparent 25px),
          radial-gradient(circle at bottom left, $bg 23px, #fff 24px, transparent 25px),
        ;
        background-position:
          top -10px left -10px,
          top -10px right -10px,
          bottom -10px right -10px,
          bottom -10px left -10px,
        ;
        border: 10px solid $bg;
      }
    }
    &:after{
      // outside circle
      background: transparent no-repeat;
      background-size: 60px 60px;
      background-image:
        radial-gradient(palettes(main,lightest) 64%, transparent 66%),
        radial-gradient(palettes(main,lightest) 64%, transparent 66%),
        radial-gradient(palettes(main,lightest) 64%, transparent 66%),
        radial-gradient(palettes(main,lightest) 64%, transparent 66%),
      ;
      background-position:
        top -30px left -30px,
        top -30px right -30px,
        bottom -30px right -30px,
        bottom -30px left -30px,
      ;
      @include mq(sp) {
        background-size: 30px 30px;
        background-image:
          radial-gradient(palettes(main,lightest) 64%, transparent 66%),
          radial-gradient(palettes(main,lightest) 64%, transparent 66%),
          radial-gradient(palettes(main,lightest) 64%, transparent 66%),
          radial-gradient(palettes(main,lightest) 64%, transparent 66%),
        ;
        background-position:
          top -15px left -15px,
          top -15px right -15px,
          bottom -15px right -15px,
          bottom -15px left -15px,
        ;
      }
    }
  }
}