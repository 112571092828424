/* ========
list-shop-links
======== */

.o-list-shop-links{
  padding: 60px 0;
  @include mq(sp) {
    padding: 32px 0;
  }
  &__content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .c-link-shop{
      margin: 0 20px;
      @include mq(sp) {
        margin: 8px 15px;
      }
    }
  }
}