/* ========
lead-pages
======== */

.o-lead-pages{
  >.l-inner{
    position: relative;
    z-index: 0;
    padding: 0 100px 100px;
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    .c-box-caption{
      @include mq(pc) {
        margin: 140px 0 0;
        &:nth-child(1){
          margin-top: -140px;
          .c-box-caption__img{
            margin: 0 0 0 -50px;
          }
        }
        &:nth-child(2){
        }
        &:nth-child(3){
          margin-top: -50px;
        }
        &:nth-child(4){
          .c-box-caption__img{
            margin: 0 -50px 0 0;
          }
        }
      }
      @include mq(sp) {
        margin: 30px 0 0;
        &:nth-child(1){
          .c-box-caption__img{
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(2){
          .c-box-caption__img{
            margin: 0 -20px 0 0;
          }
        }
        &:nth-child(3){
          .c-box-caption__img{
            margin: 0 0 0 -20px;
          }
        }
        &:nth-child(4){
          .c-box-caption__img{
            margin: 0 -20px 0 0;
          }
        }
      }
    }
  }
}