//-----------------------
.c-list-sort{
  display: flex;
  justify-content: flex-end;
  &__txt{
    font-family: $cinzelD;
    font-size: 1.5rem;
    letter-spacing: .02em;
    @include mq(sp){
      font-size: 1.4rem;
    }
  }
  &__item{
    display: block;
    width: 110px;
    height: 28px;
    font-size: 1.6rem;
    letter-spacing: .02em;
    color: $color_main;
    position: relative;
    margin-left: 20px;
    @include mq(sp){
      width: 80px;
      font-size: 1.4rem;
      margin-left: 10px;
    }
    p{
      border-bottom: 1px solid $color_main;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      padding-left: 20px;
      @include mq(sp){
        padding-left: 10px;
      }
      &:before{
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: $color_main transparent transparent transparent;
        position: absolute;
        top: 10.5px;
        right: 23px;
        bottom: 0;
        @include mq(sp){
          right: 8px;
        }
      }
    }
    ul{
      display: none;
      background-color: $color_main;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      overflow: hidden;
      z-index: 10;
      li{
        a{
          display: block;
          color: #fff;
          cursor: pointer;
          text-align: center;
          font-size: 1.6rem;
          letter-spacing: .02em;
          @include mq(sp){
            font-size: 1.4rem;
          }
        }
        &:nth-child(n+2){
          border-top: 1px solid #fff;
        }
      }
    }
    &.listAccordion{
      &.-active{
        .c-list-sort__item ul{
          display: block;
        }
      }
    }
  }
}