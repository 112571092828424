/* ========
editor-basic
======== */

.o-editor-basic{
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 2;
  text-align: justify;
  h1,h2{
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.8;
    border-bottom: 1px solid currentColor;
    padding: 8px 0 12px;
    margin: 2em 0 .5em;
  }
  h3,h4{
    font-size: 1.7rem;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 2;
    color: palettes(main);
    margin: 1.5em 0 .5em;
  }
  h5,h6{
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 2;
  }
  p{
    margin: 1em 0;
  }
  strong{
    font-weight: 700;
    color: palettes(main);
  }
  i{
    font-family: serif;
    font-style: italic;
  }
  blockquote{
    color: palettes(main);
    border: 1px solid currentColor;
    padding: 48px 56px;
    margin: 24px 0;
    @include mq(sp) {
      padding: 24px 32px;
    }
    p{
      font-size: inherit;
      margin: 0;
      & + p{
        margin-top: 1em;
      }
    }
  }
  ul{
    li{
      &:before{
        content: '●';
        margin: 0 .25em 0 0;
        display: inline-block;
        transform: scale(.8);
      }
    }
    ul{
      margin: 0 0 0 1.25em;
      li{
        &:before{
          content: '〇';
        }
      }
    }
  }
  ol{
    counter-reset: countOl;
    li{
      &:before{
        counter-increment: countOl;
        content: counter(countOl);
        background-color: $color_text;
        color: #fff;
        font-size: 1rem;
        font-weight: 700;
        width: 1.5rem;
        height: 1.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        letter-spacing: 0;
        // padding: .2em 0 0;
        margin: 0 .25em 0 0;
      }
    }
    ol{
      counter-reset: countOldouble;
      margin: 0 0 0 1.25em;
      li{
        &:before{
          counter-increment: countOldouble;
          content: counter(countOldouble);
        }
      }
      ol{
        counter-reset: countOltriple;
        margin: 0 0 0 1.25em;
        li{
          &:before{
            counter-increment: countOltriple;
            content: counter(countOltriple);
          }
        }
      }
    }
  }
  hr{
    margin: 40px 0;
    border-color: currentColor;
  }
  .c-link-relation{
    margin: 40px 0;
  }
  dl{
    display: flex;
    border-bottom: 1px solid $color_text;
    padding: 16px 0;
  }
  dt{
    flex: 0 0 auto;
    min-width: 200px;
    @include mq(sp) {
      min-width: 104px;
    }
  }
  a{
    text-decoration: underline;
    font-weight: 700;
    word-break: break-all;
  }
}
