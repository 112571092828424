//-----------------------
.c-img-hero-cuisine{
  width: 100vw;
  min-width: $pc-min-width;
  height: 600px;
  position: relative;
  z-index: 0;
  margin: 0 0 0 50%;
  transform: translate(-50%,0);
  @include mq(sp) {
    height: 220px;
    min-width: $sp-min-width;
  }
  &__img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    figure{
      background: transparent center center no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    img{
      display: none;
    }
  }
  &__ttl{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    line-height: 1;
    text-align: center;
  }
  &__small{
    display: inline-block;
    font-family: $cinzelD;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: .1em;
    text-indent: .1em;
    color: #323232;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
  &__serif{
    position: absolute;
    top: 27%;
    left: 76%;
    font-family: $quentin;
    font-size: 2.6rem;
    font-weight: 500;
    color: palettes(pink);
    transform: rotate(-8deg);
    display: inline-block;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  &__label{
    display: block;
    font-family: $cinzelD;
    font-size: 4.4rem;
    color: #323232;
    letter-spacing: .45em;
    text-indent: .45em;
    margin: 16px 0 0;
    @include mq(sp) {
      font-size: 3.3rem;
    }
  }
}