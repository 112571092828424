/* ========
foot-base
======== */

.o-foot-base{
  background-color: #fff;
  padding: 40px 0 32px;
  @include mq(sp) {
    padding: 32px 40px 88px;
  }
  .c-link-divider{
    justify-content: center;
    margin: 8px 0;
    @include mq(sp) {
      margin: 12px 0;
    }
  }
  a{
    transition: opacity .3s ease;
    @include mq(pc) {
      &:hover{
        opacity: .6;
      }
    }
  }
}