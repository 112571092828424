/* ========
intro-atrium
======== */

.o-intro-atrium{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  padding-bottom: 120px;
  @include mq(sp){
    padding-bottom: 64px;
  }
  .o-hero-caption{
    .l-inner{
      padding: 0;
      @include mq(sp){
        padding: 0 20px 56px;
      }
      &:before{
        bottom: -140px;
      }
    }
    &__img{
      @include mq(pc){
        height: 500px;
      }
      @include mq(sp){
        width: auto;
        figure{
          background-position: center top;
        }
      }
    }
    &__textarea{
      @include mq(pc){
        padding-top: 72px;
      }
      .c-ttl-section{
        margin-bottom: 72px;
      }
    }
  }
  &__content{
    position: relative;
    z-index: 1;
    padding-top: 80px;
    @include mq(sp) {
      padding-top: 64px;
    }
    .l-inner{
      max-width: 1000px;
      @include mq(sp) {
        width: 100%;
        padding: 0 20px;
      }
    }
    .c-box-images{
      &__img{
        width: 520px;
        height: 700px;
        @include mq(sp){
          width: 100%;
          height: 431px;
        }
        &__main{
          height: 100%;
        }
      }
      &__textarea{
        margin-top: 240px;
        padding: 80px 56px 52px;
        @include mq(sp){
          margin-top: 0;
          padding: 30px 0;
        }
        &:before{
          left: -130px;
          @include mq(sp){
            top: -50px;
            right: -130px;
          }
        }
      }
    }
    .c-img-hero{
      height: 400px;
      margin-top: 80px;
      @include mq(sp){
        height: 256px;
        margin: 32px -15px 0;
      }
    }
    .o-unit-photobox{
      margin-top: 80px;
      @include mq(sp){
        margin-top: 34px;
      }
    }
  }
}