//-----------------------
.c-copy-large{
  font-family: $cormorant;
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: .1em;
  color: palettes(main);
  line-height: 1.4;
  @include mq(sp) {
    font-size: 2rem;
  }
}