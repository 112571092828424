//-----------------------
.c-box-event{
  width: 220px;
  position: relative;
  z-index: 0;
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    top: -6px;
    right: -6px;
    bottom: -6px;
    background-color: palettes(main);
    z-index: -1;
  }
  a{
    display: block;
    width: 100%;
    background-color: #fff;
    color: #323232;
    @include mq(pc) {
      &:hover{
        .c-box-event__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
  }
  &__img{
    height: 170px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    img{
      display: none;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
  }
  &__textarea{
    margin: -30px 0 0;
    padding: 0 0 8px;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      min-height: 100px;
    }
  }
  &__schedule{
    background-color: #fff;
    display: inline-block;
    padding: 4px 20px;
  }
  &__category{
    font-size: 1.5rem;
    display: block;
    line-height: 1.4;
    padding: 6px 0;
  }
  &__date{
    font-family: $cormorant;
    font-size: 2.1rem;
    font-weight: 400;
    letter-spacing: .15em;
    margin: 0 .5em 0 0;
  }
  &__time{
    font-family: $roboto;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: .05em;
  }
  .c-desc{
    padding: 0 20px;
    line-height: 1.8;
  }


  &__copy{
    font-family: $cormorant;
    padding: 0 20px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4;
    color: palettes(main);
  }

  &.-simple{
    .c-box-event__textarea{
      margin: 0;
      padding: 12px 0 8px;
    }
  }

  &.-report{
    .c-box-event__textarea{
      min-height: 158px;
    }
  }
}