/* ========
intro-dress
======== */

.o-intro-dress{
  // background-color: palettes(main,lightest);
  padding: 0 0 100px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 1320px;
    height: 756px;
    background: transparent url(../images/common/bg_texture02.png) center center no-repeat;
    background-size: contain;
    left: 50%;
    bottom: -124px;
    transform: translate(-50%,0);
    opacity: .75;
    @include mq(sp) {
      width: 160%;
      background-position: center bottom;
    }
  }
  .l-inner{
    width: 1000px;
    background-color: #fff;
    margin-top: -80px;
    padding: 56px 0 100px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      margin-top: -40px;
      width: calc(100% - 40px);
      padding: 40px 0 20px;
    }
  }
  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    top: 220px;
    right: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 140px;
      right: -8px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  &__head{
    margin: 0 0 100px;
    @include mq(sp) {
      margin: 0 0 32px;
    }
    >.c-desc{
      text-align: center;
      @include mq(sp) {
        text-align: justify;
      }
    }
  }
  .c-ttl-section{
    margin: 0 0 40px;
  }
  .c-copy-basic{
    margin: 0 0 24px;
    text-align: center;
    @include mq(sp) {
      text-align: left;
    }
  }
  .c-box-caption{
    @include mq(pc) {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: 0 48px;
      &__img{
        width: 550px;
        background: transparent center center no-repeat;
        background-size: cover;
        margin: 0 0 0 -104px;
        &:before{
          content: '';
          display: block;
          padding: 80% 0 0;
        }
        img{
          display: none;
        }
      }
      &__textarea{
        width: 390px;
        flex: 0 0 auto;
        margin: 0 0 0 56px;
        &:before{
          content: none;
        }
      }
    }
    @include mq(sp) {
      &__img{
        margin: 0 -20px;
      }
    }
  }
}