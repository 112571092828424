/* ========
loading
======== */

.c-pagination{
  margin: 80px 0 0;
  @include mq(sp) {
    margin: 56px 0 0;
  }
  ul{
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 1.8;
    letter-spacing: .02em;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  li{
    margin: 0 1px;
    >a{
      @include mq(pc) {
        &.next, &.prev{
          &:hover{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $color_text;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .4s ease;
        opacity: 0;
      }
    }
    >span, >a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: #fff;
      position: relative;
      @include mq(sp) {
        width: 24px;
        height: 24px;
      }
      &.next, &.prev{
        width: auto;
        background-color: transparent;
        border: none;
      }
      &.next{
        margin: 0 0 0 30px;
        padding: 0 0 0 10px;
      }
      &.prev{
        margin: 0 30px 0 0;
        padding: 0 10px 0 0;
        .iconSvg{
          transform: scale(-1,1);
        }
      }
      .iconSvg{
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 .5em;
        @include mq(sp) {
          width: 8px;
          height: 8px;
        }
        svg{
          fill: palettes(main);
        }
      }
    }
    >span.current{
      background: palettes(main,thin);
      color: #fff;
    }
  }
}