/* ========
l-header
======== */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 16px 0 8px;
  transition: background .4s ease;
  min-width: $pc-min-width;
  @include mq(sp) {
    padding: 12px 0 0;
    min-width: $sp-min-width;
  }
  .c-logo{
    margin-bottom: 16px;
  }


  // color
  &.-fixed{
    @include mq(pc) {
      background-color: rgba(#fff,.9);
    }
    .c-logo{
      a{
        .iconSvg{
          svg{
            .text{
              fill: #323232;
            }
          }
        }
      }
    }
    .o-gnav{
      li{
        color: #323232;
      }
    }
  }
  &.-menu{
    .c-logo{
      a{
        .iconSvg{
          svg{
            .text{
              fill: #323232;
            }
          }
        }
      }
    }
    .o-gnav{
      li{
        color: #323232;
      }
    }
  }

  .c-logo{
    &.-white{
      a{
        .iconSvg{
          svg{
            .text{
              @include mq(sp) {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}