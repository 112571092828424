///*------------------------------
// 変数
// -------------------------------*/
//フォント
@font-face {
  font-family: 'Quentin';
  src: 
       url('../fonts/Quentin.eot?') format('eot'), 
       url('../fonts/Quentin.woff') format('woff'), 
       url('../fonts/Quentin.woff2') format('woff2'), 
       url('../fonts/Quentin.ttf')  format('truetype'),
       url('../fonts/Quentin.otf') format('opentype');
}

//色
$color_text: #323232;
$color_link: #323232;
$color_main: #b48c51;
$color_blue: #28b4a0;
$color_pink: #d22864;
$color_gray: #d1d1d1;
$color_beige: #dfdad6;

//カラーバリエーション
$palettes: (
  main: (
    base: $color_main,
    light: #cac1bb,
    dark: #463832,
    lightest: #efeceb,
    darkest: #311e0a,
    thin: #cbbfb6,
    thinned: #f5f3f1,
  ),
  blue: (
    base: $color_blue,
    light: #a3ddd2,
    dark: darken($color_blue, 10%),
    lightest: #e7f7f4,
    darkest: #6297a1,
  ),
  pink: (
    base: $color_pink,
    light: lighten($color_pink, 10%),
    dark: darken($color_pink, 10%),
    lightest: lighten($color_pink, 30%),
  ),
  beige: (
    base: $color_beige,
    light: #e7e2de,
    dark: #ddd5ce,
  ),
);

//font
$gothic: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
$serif: '游明朝', YuMincho, 'Hiragino Mincho ProN W3', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
$cinzel: 'Cinzel', $serif;
$cinzelD: 'Cinzel Decorative', cursive, $cinzel, $serif;
$cormorant: 'Cormorant Infant', $serif;
$quentin: 'Quentin', $serif;
$roboto: 'Roboto', $gothic;

// レイアウト
$pc-min-width: 1200px;
$sp-min-width: 360px;

//breakpoint記述
//@include mq(sp){width:100%;}
$bp-sp: 750px !default;