//-----------------------
.c-ttl-sub{
  font-size: 1.5rem;
  line-height: 2.1;
  letter-spacing: .1em;
  font-weight: 500;
  @include mq(sp){
    font-size: 1.4rem;
    letter-spacing: .02em;
    &:before{
      content: '―';
      color: #997751;
      font-weight: 400;
      margin-right: .5em;
    }
  }
}