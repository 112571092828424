//-----------------------
.c-item-case-simple{
  width: 200px;
  border-left: 1px solid palettes(main,light);
  border-right: 1px solid palettes(main,light);
  a{
    display: block;
    text-align: center;
    color: palettes(main);
    line-height: 1;
    padding: 4px 0;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        &:after{
          opacity: 1;
          background-color: palettes(main,light);
          transform: translate(0,0);
        }
      }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: calc(100% + 8px);
      left: -1px;
      right: -1px;
      height: 4px;
      background-color: palettes(main);
      pointer-events: none;
      opacity: 0;
      transition: .4s ease;
      transform: translate(0,4px);
      @include mq(sp) {
        height: 2px;
        top: calc(100% + 4px);
      }
    }
  }
  &__label{
    font-family: $quentin;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0 0 8px;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
  &__ttl{
    font-size: 2.2rem;
    letter-spacing: .1em;
    @include mq(sp) {
      font-size: 2rem;
    }
  }

  &.-current{
    a{
      &:after{
        opacity: 1;
        transform: translate(0,0);
      }
    }
  }
}