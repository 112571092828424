/* ========
intro-media01
======== */

.o-intro-media01{
  background-color: palettes(main,lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 80px;
  }
  .l-inner{
    width: 1000px;
    background-color: #fff;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: calc(100% - 40px);
    }
    &:nth-of-type(1){
      margin-top: -80px;
      padding: 56px 0 100px;
      @include mq(sp) {
        margin-top: -40px;
        padding: 40px 0 0;
      }
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    // top: 80px;
    top: 160px;
    right: -10px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      top: 120px;
      right: -8px;
    }
    svg{
      fill: palettes(main,lightest);
    }
  }
  &__head{
    margin: 0 0 100px;
    @include mq(sp) {
      padding: 0 30px;
      margin: 0 0 56px;
    }
  }
  .c-ttl-section{
    margin: 0 0 40px;
  }
  .c-copy-basic{
    margin: 0 0 24px;
    text-align: center;
    @include mq(sp) {
      text-align: left;
    }
  }
  &__content{
    .c-box-basic{
      &:nth-child(n+2){
        margin: 100px 0 0;
        @include mq(sp) {
          margin: 56px 0 0;
        }
      }
      &__textarea {
        @include mq(sp) {
          padding-bottom: 40px;
        }
      }
    }
  }
  &__foot{
    // margin: 96px 0 0;
  }
}

/* ========
intro-media02
======== */
.o-intro-media02 {
  background-color: palettes(main, lightest);
  padding: 0 0 100px;
  @include mq(sp) {
    padding: 0 0 64px;
  }
  .c-ttl-section {
    background-color: #fff;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .l-inner {
    width: 1000px;
    position: relative;
    z-index: 0;
    background-color: #fff;
    // margin-top: 80px;
    padding: 60px;
    @include mq(sp) {
      margin-top: 40px;
      width: 100%;
      padding: 15px;
    }
    > .c-ttl {
      font-size: 2.8rem;
      letter-spacing: 0.2em;
      margin-bottom: 50px;
      // margin-top: 80px;
      text-align: center;
      font-weight: normal;
    }
    .c-box-images {
      .c-copy {
        margin-top: 0;
        font-size: 2.2rem;
      }
      &__img {
        width: 440px;
        @include mq(sp){
            width: 100%;
        }
        &__main {
          height: 320px;
          @include mq(sp){
            height: auto;
            margin-bottom: 20px;
          }
        }
      }
      &__textarea {
        padding: 0 60px 0 0;
        margin: 0;
        @include mq(sp){
            padding: 0;
        }
      }
    }
    .c-box-images.-multiple-images {
      .c-copy {
        margin-top: 0;
        font-size: 2.2rem;
      }
      .c-box-images__img {
        width: 460px;
        display: grid;
        grid-template-areas: 
          "A A"
          "B C";
        gap: 4px;
        margin-left: -120px;
        margin-right: 60px;
        @include mq(sp){
          width: 100%;
          margin-bottom: 20px;
          margin-left: 0;
          margin-right: 0;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @include mq(sp) {
            height: auto;
          }
        }
        .c-box-images__img__main {
          height: 320px;
          grid-area: A;
          @include mq(sp){
            height: auto;
            position: relative;
            margin-bottom: 0;
          }
        }
        .c-box-images__img__sub01, .c-box-images__img__sub02 {
          width: 100%;
          height: 160px;
          @include mq(sp) {
            height: auto;
          }
          figure {
            width: 100%;
            height: 100%;
            @include mq(sp) {
              height: auto;
            }
          }
        }
        .c-box-images__img__sub01 {
          grid-area: B;
        }
        .c-box-images__img__sub02 {
          grid-area: C;
        }
      }
      .c-box-images__textarea {
        padding: 0;
        margin: 0;
        .c-desc{
            display: flex;
            flex-wrap: wrap;
            .-main{
                width: 110px;
            }
            .-desc{
                width: calc(100% - 110px);
            }
        }
      }
    }
  }
}
