//-----------------------
.c-modal-reserve{
  opacity: 0;
  pointer-events: none;
  transition: 1s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  margin: 0 auto;
  width: 100%;
  background-color: palettes(main,thinned);
  @include mq(sp) {
    overflow-y: scroll;
    padding: 72px 15px 0;
  }

  &.-active{
    opacity: 1;
    pointer-events: auto;
  }
  .c-calendar{
    margin: 16px 0 12px;
    & + .c-desc{
      margin-bottom: 16px;
    }
  }
  &__content{
    @include mq(pc){
      display: flex;
      align-items: center;
      height: calc(100% - 149px);
      overflow-y: scroll;
    }
  }
  &__main{
    max-width: 1000px;
    margin: auto;
    @include mq(pc){
      display: flex;
      justify-content: space-between;
    }
    .c-desc{
      color: $color_text;
      text-align: center;
    }
    .c-ttl-mix__en{
      text-align: center;
      @include mq(sp){
        font-size: 3rem;
      }
    }
    .c-btn{
      width: 321px;
      height: 54px;
      margin: 0 auto;
      @include mq(sp){
        width: 240px;
        height: 42px;
      }
    }
  }
  &__visit{
    width: calc(50% - 60px);
    @include mq(sp){
      width: 100%;
    }
    .c-modal-reserve__img{
      width: calc(100% - 40px);
      margin: 32px auto 0;
      position: relative;
      @include mq(sp){
        margin-top: 24px;
      }
      &:after{
        content: "";
        display: block;
        width: 170px;
        height: 130px;
        background: transparent url(../images/reserve/img_reserve02.jpg) center center no-repeat;
        background-size: cover;
        position: absolute;
        top: 236px;
        right: -60px;
        @include mq(sp){
          display: none;
        }
      }
    }
    .c-desc.-box{
      background: #fff;
      padding: 140px 160px 54px 28px;
      margin-top: -120px;
      @include mq(sp){
        margin-top: -60px;
        padding: 72px 24px 48px;
      }
    }
    .c-btn{
      margin-top: -28px;
      & + .c-btn{
        margin-top: 0;
      }
      @include mq(sp) {
        margin-bottom: 8px;
      }
    }
    
  }
  &__fair{
    width: calc(50% - 60px);
    @include mq(sp){
      width: 100%;
      margin-top: 56px;
      .c-btn{
        margin-top: 12px;
      }
    }
  }
  &__footer{
    background: $color_beige;
    min-width: 100%;
    padding: 30px 0;
    @include mq(pc){
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
    @include mq(sp){
      padding: 20px 48px;
      margin: 56px -15px 0;
      display: flex;
      justify-content: center;
    }
    .l-inner{
      max-width: 1000px;
      margin: auto;
      display: flex;
      align-items: center;
      @include mq(sp){
        display: block;
      }
    }
    .c-modal-reserve__item{
      width: 50%;
      display: flex;
      align-items: center;
      @include mq(sp){
        width: 100%;
        align-items: flex-start;
        position: relative;
      }
      &.-fair{
        @include mq(sp){
          margin-top: 20px;
        }
        .c-box-icon{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
      .c-modal-reserve__icon{
        width: 66px;
        height: 66px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq(sp){
          width: 50px;
          height: 50px;
          flex: 0 0 auto;
          position: absolute;
          top: 0;
          left: 0;
        }
        &.-tel{
          svg{
            width: 33px;
          }
        }
        &.-board{
          svg{
            width: 23px;
          }
        }
      }
    }
  }
}