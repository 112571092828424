//-----------------------
.c-copy-price{
  font-size: 2.2rem;
  line-height: 1.4;
  letter-spacing: .1em;
  color: $color_main;
  border-bottom: 1px solid $color_main;
  @include mq(sp){
    letter-spacing: .05em;
  }
}