/* ========
lead-shop
======== */

.o-lead-shop{
  padding: 80px 0 100px;
  background: transparent url(../images/cuisine/img_bg01.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 56px 0;
  }
  .c-ttl-section{
    margin: 0 0 40px;
    @include mq(sp) {
      margin: 0 0 32px;
    }
  }
  &__list{
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      width: 100%;
      display: block;
      padding: 0 20px;
    }
    .c-item-shop{
      // margin: 0 10px 0 0;
      @include mq(sp) {
        &:nth-child(n+2){
          margin: 40px 0 0;
        }
      }
    }
  }
}