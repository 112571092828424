/* ========
lead-information
======== */

.o-lead-information{
  padding: 80px 0;
  border-top: 1px solid #dfdad6;
  .c-ttl-section{
    margin: 0 0 56px;
  }
  &__scrollable{
    position: relative;
    z-index: 0;
    @include mq(sp) {
      &:before{
        content: 'Swipe';
        display: block;
        position: absolute;
        font-family: $roboto;
        font-size: 1.1rem;
        letter-spacing: .05em;
        font-weight: 400;
        left: 20px;
        top: 76px;
        transform: rotate(90deg);
        pointer-events: none;
        z-index: 2;
      }
      &:after{
        content: '';
        width: 20px;
        height: 1px;
        background-color: palettes(main);
        display: block;
        position: absolute;
        left: 0;
        top: 86px;
        pointer-events: none;
        z-index: 2;
      }
    }
  }
  &__list{
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    margin: 0 0 56px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 0 48px;
      overflow-x: scroll;
    }
    &.-regular{
      @include mq(pc) {
        justify-content: flex-start;
        .c-item-information{
          margin-right: 60px;
          &:nth-child(2n){
            margin-right: 0;
          }
        }
      }
    }
    &.-small{
      @include mq(pc) {
        justify-content: flex-start;
        .c-item-information{
          margin-right: 13px;
          &:nth-child(4n){
            margin-right: 0;
          }
        }
      }
    }
    .c-item-information{
      @include mq(sp) {
        flex: 0 0 auto;
        margin: 0 16px;
        &:last-child{
          padding-right: 40px;
          box-sizing: content-box;
        }
      }
    }
  }
}