//-----------------------
.c-link-list{
  $fontSizeSmall: 1.4;
  $fontSizeSmallSP: 1.2;
  @include mq(sp) {
    margin-left: 0;
    margin: 24px 40px;
  }
  li{
    line-height: 1.8;
  }
  a{
    display: inline-block;
    font-size: $fontSizeSmall+rem;
    color: #fff;
    letter-spacing: .02em;
    @include mq(sp) {
      font-size: $fontSizeSmallSP+rem;
    }
    &:before{
      content: '▶';
      display: inline-block;
      transform: scale(#{0.6/$fontSizeSmall});
      transform-origin: left center;
      margin-right: -#{$fontSizeSmall/0.6/10}em;
      color: palettes(main);
      @include mq(sp) {
        transform: scale(#{0.6/$fontSizeSmallSP});
        margin-right: -#{$fontSizeSmallSP/0.6/10}em;
      }
    }
  }
}