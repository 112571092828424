/* ========
intro-forguest
======== */

.o-intro-forguest {
  background-color: #f4f3f1;
  position: relative;
  z-index: 0;
  .o-hero-caption {
    .l-inner {
      &:before {
        bottom: -80px;
      }
    }
  }
  &__content {
    position: relative;
    z-index: -1;
    padding: 178px 0 128px;
    @include mq(sp) {
      padding: 106px 0 64px 0;
    }
    .l-inner {
      width: 1000px;
      @include mq(sp) {
        width: 100%;
        padding: 0 15px;
      }
      .c-box-images {
        margin: 40px 0 0;
        &:nth-of-type(n + 2) {
          margin: 150px 0 0;
          @include mq(sp) {
            margin: 64px 0 0;
          }
        }
        &__img{
          @include mq(sp) {
            margin-left: -15px;
          }
        }
        &__textarea {
          padding: 56px 60px 72px;
          @include mq(sp){
            margin-top: 0;
            padding: 30px;
          }
          &:before {
            left: -50px;
            @include mq(sp){
              top: -40px;
            }
          }
        }
        &.-rev {
          .c-box-images__img{
            @include mq(sp){
              margin-left: auto;
              margin-right: -15px;
            }
          }
          .c-box-images__textarea {
            margin: -60px 0 0;
            padding: 56px 60px 100px;
            @include mq(sp){
              margin: 0;
              padding: 30px;
            }
            &:before {
              right: -50px;
              left: 0;
            }
          }
        }
        &.-row {
          margin-top: 80px;
          padding: 40px;
          background-color: #fff;
          @include mq(sp){
            padding: 15px;
          }
          .c-box-images__img {
            width: 420px;
            @include mq(sp){
              width: 100%;
              margin-left: 0;
              margin-bottom: 20px;
            }
            &__main {
              height: 280px;
              @include mq(sp){
                height: 100%;
              }
            }
          }
          .c-box-images__textarea {
            margin: 0;
            padding: 0 20px 72px 60px;
            @include mq(sp){
              padding: 0;
            }
            &:before {
              display: none;
            }
            .c-btn {
              right: 0;
              bottom: 0;
            }
          }
        }
        .c-copy {
          margin-top: 0;
          font-size: 2.4rem;
          line-height: 1.25;
          margin-bottom: 10px;
        }
        .c-btn {
          width: 280px;
          & + .c-btn{
            @include mq(pc) {
              margin-bottom: -58px;
            }
          }
        }
      }
    }
  }
}

.o-intro-rental {
  padding: 100px 0;
  background-color: #fff;
  @include mq(sp) {
    padding: 60px 0 40px;
  }
  .l-inner {
    width: 1000px;
    @include mq(sp) {
      width: 100%;
      padding: 0 20px;
    }
  }
  .c-ttl-section {
    margin-bottom: 50px;
    @include mq(sp) {
      margin-bottom: 25px;
    }
  }
  .c-box-caption {
    &:not(:last-child) {
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
    }
    @include mq(pc) {
      width: 100%;
      display: flex;
      align-items: flex-start;
      &.-rev {
        flex-direction: row-reverse;
        .c-box-caption__textarea {
          margin-top: 0;
          &__inner {
            .c-btn {
              position: static;
            }
          }
        }
      }
      &__img {
        width: 500px;
      }
      &__textarea {
        width: 500px;
        margin: 110px 0 0;
        &:before {
          width: auto;
          top: -40px;
          left: -60px;
        }
        &__inner {
          padding-left: 0;
          .c-ttl.-main {
            color: $color_main;
          }
          .c-btn {
            right: 0;
            bottom: 0;
            position: absolute;
          }
          .-list {
            font-size: 1.4rem;
            letter-spacing: 0.02em;
            li {
              position: relative;
              padding-left: 16px;
              &:before {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background-color: $color_main;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -2px;
              }
            }
          }
        }
      }
    }
    @include mq(sp) {
      &__img {
        margin: 0 0 0 -20px;
        padding: 70% 0 0;
      }
      &__textarea {
        margin: 0;
      }
    }
    .c-ttl-item {
      display: flex;
      align-items: center;
      font-size: 2.4rem;
      letter-spacing: 0.02em;
      @include mq(sp){
        flex-direction: column;
        align-items: flex-start;
      }
      .-sub {
        border-left: 1px solid $color_main;
        font-size: 1.5rem;
        letter-spacing: 0.02em;
        padding-left: 20px;
        margin-left: 20px;
        @include mq(sp){
          margin-left: 0;
        }
      }
    }
  }
}

.o-lead-facility {
  background-color: #f4f3f1;
  padding: 80px 0;
  .c-ttl-section {
    margin: 0 0 36px;
  }
  .l-inner {
    max-width: 1000px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -20px 28px -20px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 0 15px;
      margin: 0 0 0;
    }
    &.-bg{
      background-color: #fff;
      margin: 0 0 56px 0;
      padding: 30px;
      @include mq(sp) {
        width: calc(100% - 30px);
        margin: 0 auto 28px auto;
      }
    }
    &.-sub{
      background-color: transparent;
      margin-bottom: 0;
      @include mq(sp) {
        margin-bottom: 28px;
        padding: 0;
      }
      .c-item-facility{
        width: calc(33.33% - 40px);
        @include mq(sp) {
          width: 100%;
        }
        background-color: transparent;
        padding: 0;
        .c-ttl{
          font-weight: bold;
          font-size: 1.4rem;
          letter-spacing: .02em;
        }
        &__textarea{
          margin-top: 0;
          & + .c-ttl{
            padding-top: 20px;
          }
        }
      }
    }
  }
}

.c-item-facility {
  width: calc(50% - 40px);
  margin: 20px;
  background-color: #fff;
  padding: 30px;
  @include mq(sp) {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .c-desc.-hyphen {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  &.-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .c-desc.-hyphen {
      width: 100%;
    }
    .c-item-facility__img {
      width: 420px;
      @include mq(sp) {
        width: 100%;
      }
    }
    .c-item-facility__textarea {
      width: calc(100% - 420px);
      padding-left: 60px;
      margin-top: 0;
      @include mq(sp) {
        width: 100%;
        padding-left: 0;
      }
      .c-desc {
        margin-bottom: 20px;
      }
      .c-ttl {
        margin-bottom: 10px;
      }
      .-circle {
        font-size: 1.4rem;
        li {
          &::before {
            content: "●";
            display: inline-block;
            padding-right: 0.25em;
            font-size: 1rem;
            color: $color_pink;
          }
          &:nth-child(even) {
            &::before {
              color: $color_blue;
            }
          }
        }
      }
    }
  }
  &__img {
    width: 100%;
    height: 240px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    img {
      display: none;
    }
    figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
    }
  }
  &__textarea {
    margin: 24px 0 0;
  }
  &__date {
    display: flex;
    align-items: center;
    &__main {
      margin: 0 16px 0 0;
      .-date {
        display: block;
        font-family: $cormorant;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        line-height: 1;
      }
    }
    &__small {
      font-family: $roboto;
      line-height: 1.4;
      span {
        display: block;
      }
      .-year {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.05em;
      }
      .-day {
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.1em;
      }
    }
  }
  &__ttl {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.6;
    border-bottom: 1px solid #000;
    padding: 0 0 0.75em;
    margin: 0.75em 0;
  }
}

.c-section-nav {
  position: absolute;
  left: 0;
  bottom: -106px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include mq(sp){
    bottom: -43px;
    padding-left: 15px;
    padding-right: 15px;
  }
  li {
    width: calc(33.33% - 1px);
    margin-right: 1px;
    @include mq(sp){
      width: calc(50% - 1px);
      a{
        font-size: 1.2rem;
      }
      &:nth-of-type(3){
        width: 100%;
        margin-top: 1px;
      }
    }
  }
  .ic-arrow-down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 7px 0 7px;
    border-color: #ffffff transparent transparent transparent;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -4px;
    @include mq(sp){
      right: 10px;
      border-width: 4px 3px 0 3px;
      margin-top: -2px;
    }
  }
}

.c-ttl.-hyphen.-list-tit{
  font-size: 2rem;
  &:before {
    content: '―';
    color: #997751;
    font-weight: 400;
    margin-right: .5em;
  }
  @include mq(sp){
    padding:0;
    margin-bottom: 10px;
  }
}