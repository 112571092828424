//-----------------------
.c-box-basic{
  display: flex;
  align-items: flex-end;
  @include mq(sp) {
    display: block;
    position: relative;
    z-index: 0;
  }
  &__img{
    width: 650px;
    flex: 0 0 auto;
    position: relative;
    z-index: -3;
    margin: 0 -100px 0 -50px;
    @include mq(sp) {
      width: 100%;
      margin: 0 20px 0 -20px;
    }
  }
  &__textarea{
    padding: 0 50px;
    position: relative;
    @include mq(sp) {
      padding: 0 25px;
    }
    &:before{
      content: '';
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      z-index: -2;
      pointer-events: none;
      @include mq(sp) {
        top: -25px;
      }
    }
  }
  .c-copy{
    line-height: 1.8;
    margin: 0 0 24px;
  }

  // layout
  &.-rev{
    flex-direction: row-reverse;
    .c-box-basic__img{
      margin: 0 -50px 0 -100px;
      @include mq(sp) {
        margin: 0 -20px 0 20px;
      }
    }
  }
}