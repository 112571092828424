//-----------------------
.c-logo-group{
  width: 392px;
  margin: 0 auto;
  @include mq(sp) {
    width: 240px;
  }
  a{
    display: block;
    width: 100%;
  }
}