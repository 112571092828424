/* ========
grp-calendar
======== */

.o-grp-calendar{
  width: 432px;
  @include mq(sp) {
    width: 100%;
  }
  .c-copy-basic{
    text-align: center;
    letter-spacing: .02em;
    margin: 0 0 24px;
  }
  .c-btn{
    width: 321px;
    margin: 24px auto 0;
    @include mq(sp) {
      width: 200px;
    }
  }
}