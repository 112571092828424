/* ========
lead-gallery
======== */

.o-lead-gallery{
  width: 100%;
  height: 854px;
  background: transparent url(../images/top/bg_photo03.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    height: 250px;
    margin: 0 0 160px;
  }
  &__btns{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: 0 auto;
    @include mq(sp) {
      display: block;
      top: 100%;
      bottom: auto;
    }
    .c-btn{
      @include mq(sp) {
        margin: 8px auto 0;
      }
    }
  }
}