/* ========
loading
======== */

.home{
  .c-loading{
    opacity: 1;
    pointer-events: auto;
    display: flex;
  }
}

.c-loading{
  opacity: 0;
  pointer-events: none;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include mq(sp) {
    transform: scale(.75);
  }
  *{
    pointer-events: none;
  }
  &__count{
    font-family: $cormorant;
    font-size: 4rem;
    color: palettes(main);
    letter-spacing: .05em;
  }
  &__circle{
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    border-radius: 50%;
    display: flex;
    overflow: hidden;
    &:before{
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border: 1px solid palettes(main);
      border-radius: 50%;
      overflow: hidden;
    }
    &__left, &__right{
      width: 50%;
      position: absolute;
      top: -1px;
      bottom: -1px;
      overflow: hidden;
      #circle01, #circle02{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
      }
      #circle01{
        transform-origin: center left;
      }
      #circle02{
        transform-origin: center right;
      }
    }
    &__left{
      left: 0;
    }
    &__right{
      right: 0;
    }
  }

  &.-loaded{
    transition: opacity 2s ease, transform 3s ease;
    pointer-events: none;
    opacity: 0;
    transform: scale(1.4);
  }
}


.c-pre-load{
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  img{
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}