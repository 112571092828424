/* ========
hero-caption
======== */

.o-hero-caption{
  .l-inner{
    width: 1000px;
    display: flex;
    position: relative;
    z-index: 0;
    padding: 40px 0 0;
    @include mq(sp) {
      width: 100%;
      display: block;
      padding: 0 20px 56px;
      background-color: #fff;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: -50px;
      right: auto;
      bottom: -250px;
      width: 100vw;
      min-width: $pc-min-width;
      background-color: #fff;
      z-index: -1;
      pointer-events: none;
      @include mq(sp) {
        content: none;
        // width: auto;
        // min-width: 0;
        // left: 0;
        // right: 0;
      }
    }
  }
  &__img{
    width: 100%;
    margin: 0 56px 0 -140px;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      min-height: 450px;
    }
    @include mq(sp) {
      width: auto;
      margin: 0 -20px;
      &:before{
        content: '';
        display: block;
        padding: 68% 0 0;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
    }
    img{
      display: none;
    }
  }
  &__textarea{
    width: 440px;
    flex: 0 0 auto;
    padding: 40px 0 0;
    @include mq(sp) {
      width: 100%;
    }
    .c-ttl-section{
      margin: 0 0 64px;
    }
    .c-copy{
      margin: 0 0 16px;
    }
    .c-btn{
      margin: 24px 0 0;
    }
  }

  // layout
  &.-rev{
    @include mq(pc) {
      .l-inner{
        flex-direction: row-reverse;
        &:before{
          left: auto;
          right: -50px;
        }
      }
      .o-hero-caption__img{
        margin: 0 -140px 0 56px;
      }
    }
  }
}