/* ========
intro-weddingcake
======== */

.o-intro-weddingcake{
  background-color: palettes(main,lightest);
  position: relative;
  z-index: 0;
  padding-bottom: 120px;
  @include mq(sp){
    padding-bottom: 64px;
  }
  .o-hero-caption{
    @include mq(pc){
      .l-inner{
        padding: 0;
        &:before{
          bottom: -140px;
        }
      }
      &__textarea{
        padding: 72px 0 0;
        .c-ttl-section{
          margin: 0 0 40px;
        }
      }
    }
  }
  &__content{
    .l-inner{
      max-width: 1000px;
      @include mq(sp){
        width: calc(100% - 40px);
      }
    }
    .c-box-images{
      display: block;
      @include mq(sp){
        margin-top: 64px;
      }
      &__img{
        width: 100%;
        margin-top: 80px;
        padding-left: 60px;
        @include mq(sp){
          padding-left: 0;
          margin-top: 0;
        }
        &__main{
          @include mq(pc){
            width: 916px;
            height: 400px;
          }
        }
        // &:before{
        //   content: "";
        //   display: block;
        //   width: 330px;
        //   height: 350px;
        //   z-index: 1;
        //   position: absolute;
        //   background: url(../images/cuisine/weddingcake/img_weddingcake02.jpg) center center no-repeat;
        //   background-size: cover;
        //   top: 232px;
        //   left: 0;
        //   z-index: 3;
        //   @include mq(sp){
        //     display: none;
        //   }
        // }
      }
      &__textarea{
        width: 760px;
        margin: -60px 0 0 auto;
        padding: 100px 56px 48px 32px;
        @include mq(sp){
          width: calc(100% + 40px);
          padding: 80px 20px 30px;
          margin: -50px -20px 0;
        }
        &:before{
          left: -25px;
        }
      }
      &.-rev{
        .c-box-images{
          &__img{
            @include mq(pc){
              padding-left: 50%;
              &__main{
                width: 500px;
                height: 340px;
              }
            }
            &:before{
              display: none;
            }
          }
          &__textarea{
            @include mq(pc){
              margin-left: 0;
              margin-top: -310px;
              width: 50%;
              padding: 56px;
              &:before{
                right: -90px;
              }
            }
          }
        }
      }
    }
    .c-img-column{
      margin-top: 40px;
      @include mq(sp){
        margin: 64px -20px 0;
      }
    }
    .c-btn{
      margin: 80px auto 0;
      width: 300px;
      a{
        font-family: $serif;
        letter-spacing: .02em;
      }
      .iconSvg{
        right: auto;
        left: 14px;
        transform: rotate(180deg);
      }
    }
  }
}
