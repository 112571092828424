//-----------------------
.c-link-information{
  display: flex;
  // justify-content: space-between;
  @include mq(sp){
    flex-wrap: wrap;
  }
  &__list{
    width: 320px;
    @include mq(pc) {
      margin-right: 20px;
      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq(sp){
      width: 100%;
      background: #fff;
      &:nth-child(n+2){
        margin-top: 24px;
      }
    }
  }
  &__img{
    width: 100%;
    height: 240px;
    position: relative;
    overflow: hidden;
    @include mq(sp){
      padding: 70% 0 0;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
    }
    img{
      display: none;
    }
  }
  &__textarea{
    margin: 8px 0 0;
    @include mq(sp){
      padding: 20px;
      margin: 0;
    }
    .c-desc{
      font-size: 1.8rem;
      line-height: 1.7;
      letter-spacing: .05em;
      color: #000;
      @include mq(sp){
        font-size: 1.3rem;
      }
    }
  }
}