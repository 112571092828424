//-----------------------
.c-box-link{
  width: 354px;
  a{
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: #fff;
    position: relative;
    z-index: 0;
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent palettes(main) transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    @include mq(pc) {
      &:hover{
        .c-box-link__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
  }
  &__img{
    width: 50%;
    margin: 0 0 0 -10px;
    overflow: hidden;
    position: relative;
    flex: 0 0 auto;
    &:before{
      content: '';
      display: block;
      padding: 100% 0 0;
    }
    .bgImg{
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(0.12, 0.72, 0.46, 1);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img{
        display: none;
      }
    }
  }
  &__ttl{
    font-family: $cormorant;
    color: palettes(main);
    font-size: 2.2rem;
    color: palettes(main);
    padding: 0 8px 0 16px;
    line-height: 1.2;
    small{
      font-family: $serif;
      display: block;
      font-size: 1.1rem;
      font-weight: 500;
      color: $color_text;
      margin: 8px 0 0;
    }
  }
}