//-----------------------
.c-btn-close{
  width: 65px;
  height: 65px;
  position: fixed;
  top: 50px;
  right: 50px;
  cursor: pointer;
  @include mq(sp) {
    width: 41px;
    height: 41px;
    top: 15px;
    right: 15px;
    z-index: 10;
  }
  &:before, &:after{
    content: '';
    display: block;
    width: calc(100% - 4px);
    height: 1px;
    background-color: palettes(main);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &:before{
    transform: rotate(-45deg);
  }
  &:after{
    transform: rotate(45deg);
  }
}