/* ========
unit-infomation
======== */

.o-unit-infomation{
  &__ttl{
    font-size: 2.8rem;
    line-height: 1.4;
    letter-spacing: .2em;
    text-align: center;
    margin-bottom: 36px;
    @include mq(sp){
      margin-bottom: 24px;
      font-size: 2.4rem;
    }
  }
}