/* ========
intro-hokkaidowedding
======== */
.o-intro-hokkaidowedding {
  background-color: #f1f6f7;
  position: relative;
  z-index: 0;
  // @include mq(sp){
  //   padding: 15px;
  // }
  .c-ttl-section {
    background-color: #fff;
    padding: 70px 0;
  }

  &__head {
    text-align: center;
    padding: 60px 0;
    @include mq(sp){
      padding: 60px 0;
    }
    .c-copy-basic {
      font-size: 1.8rem;
      margin-bottom: 50px;
      @include mq(sp){
        font-size: 1.5rem;
        margin-bottom: 25px;
        padding: 0 15px;
      }
    }
    .c-btn-row {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq(sp){
        flex-direction: column;
      }
      .c-btn {
        margin: 0 10px;
        width: 320px;
        @include mq(sp){
          width: 100%;
          max-width: 320px;
          margin: 0 auto 1px auto;
        }
      }
    }
  }

  &__body {
    padding-top: 60px;
    @include mq(sp){
      padding:30px 15px;
    }
    .l-inner {
      padding: 60px;
      max-width: 1000px;
      background-color: #fff;
      @include mq(sp){
        padding: 15px;
      }
    }
    .o-detail-consultation {
      padding-top: 40px;
      .c-copy {
        font-size: 2.2rem;
        margin-bottom: 20px;
        @include mq(sp){
          font-size: 1.8rem;
          line-height: 1.5;
          margin-bottom: 15px;
        }
      }
      .c-desc {
        font-size: 1.5rem;
        letter-spacing: 0.02em;
        &:not(:last-child) {
          font-size: 1.4rem;
          margin-bottom: 20px;
        }
      }
      .img-grid-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;
        @include mq(sp){
          margin-left: -5px;
          margin-right: -5px;
        }
        li {
          width: calc(50% - 40px);
          margin: 20px;
          height: 315px;
          position: relative;
          @include mq(sp){
            width: calc(50% - 10px);
            margin: 5px;
            height: 100%;
            &:before{
              content:"";
              display: block;
              padding-top: 75%;
            }
          }
          figure {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent center center no-repeat;
            background-size: cover;
            &:before {
              content: "";
              display: block;
              padding-top: 75%;
            }
            img {
              display: none;
            }
          }
        }
      }
    }
  }

  .o-hero-caption {
    flex-direction: row-reverse;
    display: flex;
    position: relative;
    @include mq(sp){
      flex-direction: column;
    }
    &:before {
      display: none;
    }
    &__textarea {
      padding-top: 0;
      width: 400px;
      @include mq(sp){
        width: 100%;
      }
      .c-copy {
        font-size: 2.2rem;
        @include mq(sp){
          font-size: 1.8rem;
        }
      }
    }
  }

  .o-hero-caption.-rev .o-hero-caption__img {
    margin: -120px -120px 0 40px;
    @include mq(sp){
      margin: -30px -30px 30px 0;
    }
  }

  .c-box-images {
    padding-top: 60px;
    &__img {
      width: 400px;
      @include mq(sp){
        width: 100%;
      }
      &__main {
        height: 260px;
        @include mq(sp){
          height: auto;
        }
      }
    }
    &__textarea {
      margin: 0;
      padding: 0 0 0 60px;
      @include mq(sp){
        padding: 30px 0 0 0;
      }
      .c-copy {
        font-size: 2.2rem;
        margin: 0 0 16px;
        @include mq(sp){
          font-size: 1.8rem;
        }
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 60px 0 60px;
    @include mq(sp) {
      padding: 32px 15px;
    }
    .l-inner {
      width: 1000px;
      background-color: #fff;
      padding-bottom: 60px;
      @include mq(sp) {
        width: 100%;
        padding: 0 15px 15px 15px;
      }
    }
    .c-box-images {
      padding: 60px 60px 20px;
      @include mq(sp){
        padding: 15px 0 0 0;
      }
      &.-fw-wrap {
        flex-wrap: wrap;
        padding-bottom: 0;
        padding-top: 0;
        .-divider {
          width: 100%;
          border-top: 1px solid #f4f3f1;
          margin: 30px 0;
        }
      }
      &__img__main {
        height: 566px;
        @include mq(sp){
          height: 100%;
          margin-bottom: 30px;
        }
      }
      &__textarea {
        margin-top: 0;
        padding: 0;
        margin-right: auto;
        width: 400px;
        @include mq(sp){
          width: 100%;
        }
        &::before {
          display: none;
        }
        .c-ttl.-main {
          color: $color_main;
          font-size: 2rem;
          margin-bottom: 10px;
          @include mq(sp){
            font-size: 1.8rem;
          }
        }
        .c-ttl.-name {
          font-size: 2.8rem;
          margin-bottom: 20px;
        }
        .c-desc.-addr {
          font-size: 1.4rem;
          line-height: 2;
          margin-bottom: 30px;
        }
        .c-desc.-data {
          display: flex;
          flex-wrap: wrap;
          .-main {
            width: 110px;
          }
        }

        .c-ttl {
          font-size: 2.8rem;
          letter-spacing: 0.02em;
          margin-bottom: 20px;
          @include mq(sp){
            font-size: 2.0rem;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.c-ttl.-w100 {
  font-size: 2.8rem;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  width: 100%;
  @include mq(sp){
    font-size: 2.0rem;
    line-height: 1.5;
    margin-bottom: 15px;
  }
}

.c-inq-box {
  border: 1px solid $color_main;
  padding: 40px;
  margin-top: 30px;
  @include mq(sp){
    padding: 20px;
  }
  .c-btn {
    position: static;
  }
  .c-desc.-tel {
    font-family: $cormorant;
    font-size: 2.6rem;
    line-height: 1.25;
    margin-bottom: 20px;
    .-main {
      display: block;
      font-size: 1.6rem;
    }
    .-note {
      font-family: $serif;
      font-size: 1.3rem;
      padding-left: 20px;
      @include mq(sp){
        display: block;
        padding-left: 0;
      }
    }
  }
}

.c-box-addr {
  width: calc(100% - 440px);
  @include mq(sp) {
    width: 100%;
  }
  &__text {
    .c-desc.-addr {
      margin-bottom: 15px;
    }
    margin-bottom: 20px;
  }
}