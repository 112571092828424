/* ========
unit-photobox
======== */

.o-unit-photobox{
  display: flex;
  justify-content: space-between;
  @include mq(sp){
    flex-wrap: wrap;
  }
  .c-box-photo-desc{
    width: 48%;
    @include mq(sp){
      width: 100%;
      &:nth-child(n+2){
        margin-top: 32px;
      }
    }
  }
}