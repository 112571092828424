//-----------------------
.c-box-img-types{
  display: flex;
  align-items: flex-start;
  @include mq(sp) {
    display: block;
  }
  &__img{
    position: relative;
    z-index: 0;
    width: 460px;
    flex: 0 0 auto;
    @include mq(sp) {
      width: 100%;
    }
    &:before{
      content: '';
      display: block;
      padding: 69.5% 0 0;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      img{
        display: none;
      }
    }
  }
  &__textarea{
    padding: 0 0 0 80px;
    @include mq(sp) {
      padding: 24px 0 0;
    }
    &.-no-media{
      @include mq(pc) {
        padding: 0 80px;
      }
    }
  }
  &__ttl{
    display: flex;
    align-items: center;
    line-height: 1.4;
    margin: 0 0 16px;
    &__en{
      font-family: $cinzelD;
      font-weight: 400;
      letter-spacing: .1em;
      color: palettes(main);
      font-size: 2.8rem;
      &:after{
        content: '|';
        color: palettes(main,light);
        margin: 0 .5em;
      }
    }
  }
  .c-copy{
    margin: 0 0 16px;
  }


  // layout
  &.-rev{
    @include mq(pc) {
      flex-direction: row-reverse;
      .c-box-img-types__textarea{
        padding: 0 80px 0 0;
      }
    }
  }

  &.-dual{
    .c-box-img-types__img{
      &:before{
        content: none;
      }
      figure{
        position: relative;
        &:nth-child(1){
          margin: 0 -100px 0 100px;
          @include mq(sp) {
            margin: 0 0 0 50px;
          }
          &:before{
            content: '';
            display: block;
            padding: 69.5% 0 0;
          }
        }
        &:nth-child(2){
          width: 39.14%;
          margin: -35% 0 0;
          @include mq(sp) {
            width: 33.33%;
          }
          &:before{
            content: '';
            display: block;
            padding: 122.2% 0 0;
          }
        }
      }
    }
  }

  &.-multi{
    .c-box-img-types__img{
      &:before{
        content: none;
      }
      &__main{
        width: 100%;
        position: relative;
        &:before{
          content: '';
          display: block;
          padding: 60.8% 0 0;
        }
      }
      &__sub{
        display: flex;
        margin: 4px -2px 0;
        figure{
          position: relative;
          width: calc(33.33% - 4px);
          margin: 0 2px;
          &:before{
            content: '';
            display: block;
            padding: 66.66% 0 0;
          }
        }
      }
    }
  }

  &.-quattro{
    .c-box-img-types__img{
      figure{
        &:nth-child(1){
          right: calc(50% + 2px);
          bottom: calc(50% + 2px);
        }
        &:nth-child(2){
          left: calc(50% + 2px);
          bottom: calc(50% + 2px);
        }
        &:nth-child(3){
          right: calc(50% + 2px);
          top: calc(50% + 2px);
        }
        &:nth-child(4){
          left: calc(50% + 2px);
          top: calc(50% + 2px);
        }
      }
    }
  }
}