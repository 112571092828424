//-----------------------
.c-box-explain{
  background: #fff;
  width: 100%;
  display: flex;
  @include mq(sp){
    flex-wrap: wrap;
  }
  &__img{
    width: 50%;
    padding: 40px;
    @include mq(sp){
      width: 100%;
      padding: 20px;
    }
  }
  &__textarea{
    width: 50%;
    padding: 40px 48px 40px 16px;
    @include mq(sp){
      width: 100%;
      padding: 30px 20px;
    }
    .c-ttl-item.-main{
      font-size: 2.8rem;
      letter-spacing: .1em;
    }
    .c-copy{
      font-size: 2.4rem;
      letter-spacing: .02em;
    }
    .c-desc{
      margin-top: 16px;
    }
  }
}