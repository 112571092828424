//-----------------------
.c-ttl-item{
  font-family: $cinzelD;
  font-size: 3.6rem;
  font-weight: 400;
  letter-spacing: .15em;
  line-height: 1.6;

  @include mq(sp) {
    font-size: 2.8rem;
    & + .c-desc{
      font-size: 1.4rem;
    }
  }

  // color
  &.-main{
    color: palettes(main);
  }

  // font
  &.-camel{
    font-family: $serif;
  }
}