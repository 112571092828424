//-----------------------
.c-btn-large{
  width: 220px;
  height: 125px;
  @include mq(sp) {
    width: 295px;
    height: 66px;
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    background-color: palettes(main);
    padding: 24px 0 0;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        &:before{
          opacity: 1;
        }
      }
    }
    @include mq(sp) {
      padding: 8px 0 0;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: rgba(#000,.1);
      opacity: 0;
      transition: .4s ease;
    }
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-width: 0 0 12px 12px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .iconSvg{
    width: 20px;
    height: 20px;
    margin: 0 auto 10px;
    @include mq(sp) {
      position: absolute;
      top: 0;
      left: 20px;
      bottom: 0;
      margin: auto 0;
    }
    svg{
      fill: #fff;
    }
  }
  p{
    font-family: $cinzel;
    font-weight: 400;
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: .15em;
    color: #fff;
    line-height: 1.4;
    margin: 0 0 4px;
    @include mq(sp) {
      font-size: 1.9rem;
    }
    span{
      font-family: $cinzelD;
    }
  }
  small{
    font-size: $serif;
    font-weight: 500;
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: .1em;
    text-align: center;
    display: block;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }

  // color
  &.-blue{
    a{
      background-color: palettes(blue);
    }
  }
  &.-pink{
    a{
      background-color: palettes(pink);
    }
  }
  &.-black{
    a{
      background-color: #323232;
    }
  }
}