//-----------------------
.c-img-column{
  display: flex;
  &__img{
    &:nth-child(n+2){
      margin-left: 10px;
    }
  }
  &.-col2{
    .c-img-column__img{
      &:nth-child(n+2){
        margin-left: 20px;
        @include mq(sp){
          margin-left: 10px;
        }
      }
    }
    .c-img-column__item{
      &:nth-child(n+2){
        margin-top: 20px;
        @include mq(sp){
          margin-top: 10px;
        }
      }
    }
  }
}