/* ========
html
======== */
html{
	font-size: 62.5%;
}

/* ========
body
======== */
body{
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  font-family: $serif;
  color: $color_text;
  line-height: 2;
  left: 0;
  right: 0;
  font-feature-settings: 'palt' 1;

  overflow-y: scroll;

  @include mq(sp){
    min-width: $sp-min-width;
  }
}


/* ========
tags
======== */
*{
  box-sizing: border-box;
  &::selection {
    background-color: rgba(#5D8187,.5);
    color: #fff;
  }
}
a{
	color: inherit;
	outline: none;
	opacity: 1;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
a:link,
a:visited{
	text-decoration: none;
}
a:hover{
	text-decoration: none;
	outline: none;
	// opacity: 0.7;
}
a:active{
	outline: none;
}

img {
  display: block;
	max-width: 100%;
  height: auto;
}

// /* clearfix */
// .clearfix {
//   *zoom: 1;
//   &:after {
//     content: "";
//     display: block;
//     clear: both;
//   }
// }

/* ========
input
======== */
//reset
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}