//-----------------------
.o-unit-case {
  width: 400px;
  @include mq(sp) {
    width: 100%;
  }
  &__ttl {
    font-family: $cinzelD;
    font-size: 3.2rem;
    font-weight: 400;
    letter-spacing: .15em;
    line-height: 1.4;
    text-align: center;
    color: palettes(main,dark);
    margin: 0 0 20px;
    @include mq(sp) {
      font-size: 2.8rem;
    }
  }
  &__copy {
    color: palettes(main);
    font-family: $cormorant;
    font-size: 2.8rem;
    font-weight: 400;
    letter-spacing: .1em;
    border-bottom: 1px solid palettes(main);
    line-height: 1.4;
    padding: 0 0 .25em;
    margin: 0 0 20px;
    @include mq(sp) {
      font-size: 2.4rem;
    }
  }
  figure {
    > div {
      position: relative;
      z-index: 0;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding: 100% 0 0;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    figcaption {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      line-height: 1.8;
      margin: .75em 0 0;
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
  }
  &__before {
    &__inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      figure {
        width: calc(50% - 6px);
        margin: 0 0 16px;
      }
    }
  }
  &__after {
    margin: 16px 0 0;
    &__inner {
      display: flex;
      justify-content: space-between;
    }
    &__nav {
      width: calc(21% - 4px);
      li {
        position: relative;
        z-index: 0;
        cursor: pointer;
        &:nth-child(n+2) {
          margin: 8px 0 0;
        }
        &.slick-active{
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000,.2);
            border: 1px solid palettes(main);
          }

        }
      }
      button {
        display: none;
      }
      figcaption {
        display: none;
      }
    }
    &__main {
      width: 312px;
      @include mq(sp) {
        width: calc(79% - 4px);
      }
    }
  }
}