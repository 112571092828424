//-----------------------
.c-item-information{
  width: 240px;
  a{
    display: block;
    position: relative;
    z-index: 0;
    @include mq(pc) {
      &:hover{
        // &:before{
        //   width: 50%;
        //   left: calc(50% + 12px);
        //   right: auto;
        // }
        .c-item-information__img{
          figure{
            transform: scale(1.06);
          }
        }
      }
    }
    // &:before, &:after{
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   z-index: -1;
    // }
    // &:before{
    //   width: 0;
    //   top: -12px;
    //   left: auto;
    //   right: -12px;
    //   bottom: -12px;
    //   background-color: rgba(palettes(main),.5);
    //   transition: .4s ease;
    // }
    // &:after{
    //   top: -6px;
    //   left: -6px;
    //   right: -6px;
    //   bottom: -6px;
    //   background-color: #fff;
    // }
  }
  &__img{
    width: 100%;
    height: 180px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    img{
      display: none;
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent center center no-repeat;
      background-size: cover;
      transition: transform 2s cubic-bezier(.12,.72,.46,1);
    }
  }
  &__textarea{
    margin: 24px 0 0;
  }
  &__date{
    display: flex;
    align-items: center;
    &__main{
      margin: 0 16px 0 0;
      .-date{
        display: block;
        font-family: $cormorant;
        font-size: 4rem;
        font-weight: 400;
        letter-spacing: .1em;
        line-height: 1;
      }
    }
    &__small{
      font-family: $roboto;
      line-height: 1.4;
      span{
        display: block;
      }
      .-year{
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: .05em;
      }
      .-day{
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: .1em;
      }
    }
  }
  &__ttl{
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.6;
    border-bottom: 1px solid #000;
    padding: 0 0 .75em;
    margin: .75em 0;
  }
  &__tag{
    border: 1px solid currentColor;
    padding: 4px 12px;
    font-family: $cinzelD;
    font-size: 1.4rem;
    line-height: 1.6;
    text-shadow: 0 0 0 currentColor;
    letter-spacing: .12em;
    margin: 0 0 0 20px;
    @include mq(sp) {
      font-size: 1.2rem;
      margin: 0 0 0 12px;
      letter-spacing: .08em;
      padding: 4px 6px;
    }
  }
}