/* ========
lead-concept
======== */

.o-lead-concept{
  padding: 80px 0 160px;
  overflow: hidden;
  @include mq(sp) {
    padding: 50px 0;
  }
  >.l-inner{
    width: 1100px;
    background-color: palettes(main,lightest);
    padding: 80px 0 0;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: calc(100% - 40px);
      padding: 40px 0 0;
    }
  }
  .c-ttl-section{
    margin: 0 0 56px;
    @include mq(sp) {
      margin: 0 0 40px;
    }
  }
  &__deco{
    position: absolute;
    z-index: -1;
    width: 252px;
    height: 312px;
    top: 0;
    left: -20px;
    pointer-events: none;
    @include mq(sp) {
      width: 126px;
      height: 156px;
      left: -30px;
    }
    svg{
      fill: #fff;
    }
  }
  &__hero{
    width: 100vw;
    height: 660px;
    min-width: $pc-min-width;
    background: transparent url(../images/top/bg_photo01.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
    margin: 0 0 0 50%;
    transform: translate(-50%,0);
    @include mq(sp) {
      width: auto;
      height: 320px;
      min-width: $sp-min-width;
      margin: 0 -20px;
      transform: none;
      padding: 0 20px;
    }
    .c-ttl-middle{
      width: 1000px;
      margin: 0 auto;
      position: relative;
      top: 82.5%;
      font-size: 2.4rem;
      letter-spacing: .1em;
      line-height: 1.8;
      text-shadow: 0 0 6px rgba(#000,.4);
      @include mq(sp) {
        width: auto;
        top: 54%;
        font-size: 1.7rem;
        padding: 0 30px;
        text-shadow:
          0 0 12px rgba(#000,1),
          0 0 24px rgba(#000,.75),
          0 0 32px rgba(#000,.5);
      }
    }
  }
  &__content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__img01{
    width: 420px;
    flex: 0 0 auto;
    padding: 80px 0;
    margin: 0 60px;
    @include mq(sp) {
      display: none;
    }
  }
  &__textarea{
    background-color: #fff;
    padding: 50px 50px 0;
    margin: -40px 0 0;
    position: relative;
    width: 570px;
    @include mq(sp) {
      width: auto;
      padding: 30px;
      background-color: palettes(main,lightest);
    }
    .c-copy-basic{
      margin: 0 0 24px;
    }
    .c-btn{
      margin: 32px 0 -27px;
      @include mq(sp) {
        margin: 24px auto 0;
      }
    }
  }
  &__img02{
    position: absolute;
    width: 460px;
    right: 50px;
    bottom: -60px;
    @include mq(sp) {
      display: none;
    }
  }
}