//-----------------------
.c-box-case{
  // width: calc(100% - 180px);
  width: 100%;
  margin: 64px auto 0;
  border: 1px solid $color_main;
  padding: 0 60px;
  @include mq(sp){
    width: 100%;
    padding: 30px 20px;
    position: relative;
  }
  &__ttl{
    font-size: 2rem;
    letter-spacing: .2em;
    color: $color_main;
    position: relative;
    z-index: 0;
    line-height: 1.4;
    @include mq(pc){
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(0,-50%);
    }
    @include mq(sp){
      font-size: 1.6rem;
    }
    >span{
      display: inline-flex;
      align-items: center;
      background: #fff;
      padding: 0 24px;
      @include mq(sp){
        padding: 0;
        display: flex;
        align-items: flex-start;
      }
    }
    .iconSvg{
      width: 28px;
      height: 26px;
      display: block;
      margin-right: 10px;
      fill: $color_main;
      @include mq(sp){
        margin: 2px 6px 0 0;
      }
    }
    
  }
  &__list{
    @include mq(pc){
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 28px;
    }
    @include mq(sp){
      margin: 20px 0;
    }
    & + .c-desc, & + .c-desc + .c-desc {
      text-align: right;
      margin: -16px 0 28px;
      padding: 12px 0 0;
      border-top: 1px solid palettes(main,light);
      @include mq(sp) {
        margin: -12px 0 20px;
        padding: 8px 0 0;
      }
      dl{
        display: flex;
        justify-content: space-between;
        width: calc(50% - 24px);
        margin-left: auto;
        font-weight: 400;
        @include mq(sp) {
          width: 100%;
        }
        strong{
          font-weight: 700;
        }
      }
    }
    & + .c-desc + .c-desc {
      @include mq(pc) {
        width: calc(50% - 24px);
        margin-left: auto;
        dl{
          width: 100%;
        }
      }
    }
    .c-box-case__item{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 1.5rem;
      line-height: 1.4;
      letter-spacing: .02em;
      color: $color_text;
      margin: 0 0 12px;
      @include mq(pc){
        width: calc(50% - 24px);
        span{
          margin-left: 20px;
        }
      }
      @include mq(sp){
        font-size: 1.3rem;
      }
      span{
        display: flex;
        &:after{
          content: "円";
          display: block;
        }
      }
    }
  }
  &__desc{
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: .02em;
    color: $color_text;
    display: flex;
    &:before{
      content: "※";
      display: block;
    }
    @include mq(pc){
      &:last-of-type{
        margin-bottom: 42px;
      }
    }
  }


  &.-none-ttl{
    @include mq(pc) {
      padding-top: 42px;
    }
    .c-box-case__ttl{
      display: none;
    }
  }
}