/* ========
intro-propose
======== */

.o-intro-propose{
    background-color: palettes(main,lightest);
    padding: 0 0 100px;
    @include mq(sp) {
      padding: 0 0 80px;
    }
    .c-img-hero{
      background-position: center top 38%;
    }
    .o-block-place{
        background-color: transparent;
        .c-bg-deco02{
            background-color: transparent;
        }
        .c-box-images__num{
            font-size: 2.2rem;
            text-align: center;
            transform: rotate(-5deg);
        }
        .o-list-propose-service{
            padding: 0;
            background: none;
            .l-inner {
                width: 100%;
                padding: 200px 50px 0 50px;
                background: none;
                @include mq(sp) {
                  padding: 64px 30px 0;
                }
            }
        }
    }
    >.l-inner{
      width: 1000px;
      background-color: #fff;
      margin-top: -80px;
      padding: 56px 0 0;
      position: relative;
      z-index: 0;
      @include mq(sp) {
        margin-top: -40px;
        width: calc(100% - 40px);
        padding: 40px 0 0;
      }
    }
    &__deco{
      position: absolute;
      width: 436px;
      height: 436px;
      top: 220px;
      right: -96px;
      pointer-events: none;
      z-index: 1;
      mix-blend-mode: multiply;
      @media all and (-ms-high-contrast: none) {
        z-index: -1;
      }
      @include mq(sp) {
        width: 143px;
        height: 218px;
        top: 140px;
        right: -18px;
      }
      svg{
        fill: palettes(main,lightest);
      }
    }
    &__head{
      margin: 0;
      @include mq(sp) {
        padding: 0 30px;
        margin: 0;
      }
      >.c-desc{
        text-align: center;
        @include mq(sp) {
          text-align: justify;
        }
      }
    }
    .c-ttl-section{
      margin: 0 0 40px;
    }
    .c-copy-basic{
      margin: 0 0 24px;
      text-align: center;
      @include mq(sp) {
        text-align: left;
      }
    }
    &__content{
      .c-box-caption{
        &:nth-child(n+2){
          margin: 100px 0 0;
          @include mq(sp) {
            margin: 30px 0 0;
          }
        }
        &.-full{
          @include mq(pc) {
            .c-box-caption__img{
              width: calc(100% + 100px);
              min-width: 100%;
              height: 580px;
            }
            .c-box-caption__textarea{
              width: 570px;
              padding: 0 60px;
              &:before{
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
    }
    .logo-row{
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 50px auto 0 auto;
        @include mq(sp){
            margin: 25px auto 0 auto;
        }
        .logo-col{
            width: calc(25% - 60px);
            margin: 30px;
            @include mq(sp){
                width: calc(25% - 30px);
                margin: 15px;
            }
            &__img{
                width: 100%;
                position: relative;
                position: relative;
                z-index: 0;
                width: 150px;
                height: 100px;
                @include mq(sp){
                    width: auto;
                    height: auto;
                    &::before{
                        content: '';
                        display: block;
                        padding: 66.66% 0 0;
                    }
                }
                figure{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 0;
                    background: transparent center center no-repeat;
                    background-size: cover;
                    img{
                        display: none;
                    }
                }
            }
        }
    }
  }







