/* ========
block-place
======== */

.o-block-place{
  position: relative;
  z-index: 0;
  padding: 88px 21px;
  @include mq(sp) {
    padding: 56px 11px;
  }
  .c-ttl-item{
    text-align: center;
    letter-spacing: .12em;
  }
  &__intro{
    display: flex;
    align-items: flex-end;
    margin: 56px 0 60px;
    @include mq(sp) {
      display: block;
      margin: 32px 0 48px;
    }
  }
  &__img{
    position: relative;
    z-index: 0;
    width: 650px;
    height: 460px;
    margin: 0 -100px 0 -70px;
    @include mq(sp) {
      width: auto;
      height: auto;
      margin: 0 16px 0 -31px;
      &:before{
        content: '';
        display: block;
        padding: 64% 0 0;
      }
    }
    figure{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: transparent center center no-repeat;
      background-size: cover;
    }
    img{
      display: none;
    }
  }
  &__textarea{
    background-color: #fff;
    padding: 50px 48px 0;
    width: 480px;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 24px 20px 0;
      width: 100%;
    }
    .c-copy{
      line-height: 1.8;
      margin: 0 0 1.5em;
    }
    .c-desc{
      letter-spacing: 0;
      & + .c-desc{
        margin: 1em 0 0;
      }
    }
  }
  .c-btn{
    margin: 0 auto;
    position: relative;
    z-index: 10;
    @include mq(sp) {
      max-width: 90%;
    }
    a{
      font-size: 1.4rem;
    }
  }
  &__photos{
    width: 860px;
    margin: 60px auto 0;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      width: auto;
      margin: 40px -26px 0;
      display: flex;
      overflow-x: scroll;
      padding: 0 0 8px;
    }
    .c-slider-pc{
      &__arrow{
        display: none;
      }
    }
    .slick-list{
      overflow: visible;
    }
    li{
      width: 280px;
      margin: 0 5px;
      @include mq(sp) {
        width: 200px;
        flex: 0 0 auto;
      }
      figure{
        width: 100%;
        height: 180px;
        background: transparent center center no-repeat;
        background-size: cover;
        @include mq(sp) {
          height: auto;
          &:before{
            content: '';
            display: block;
            padding: 64% 0 0;
          }
        }
      }
      img{
        display: none;
      }
      p{
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.5;
        margin: 16px 0 0;
        display: none; // 非表示
      }
    }
  }

  // color
  &.-blue{
    .o-block-place__textarea{
      background-color: palettes(blue,lightest);
    }
    .c-bg-deco02{
      color: palettes(blue,lightest);
      background-color: palettes(blue,lightest);
    }
  }

  // layout
  &.-rev{
    @include mq(pc) {
      .o-block-place__intro{
        flex-direction: row-reverse;
      }
      .o-block-place__img{
        margin: 0 -70px 0 -100px;
      }
    }
  }
}