/* ========
lead-style
======== */

.o-lead-style{
  padding: 100px 0;
  background-color: #fff;
  @include mq(sp) {
    padding: 60px 0 40px;
  }
  .l-inner{
    padding: 0 100px 0 50px;
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  .c-box-caption{
    @include mq(pc) {
      width: 100%;
      display: flex;
      align-items: flex-end;
      &__img{
        width: 590px;
      }
      &__textarea{
        width: 460px;
        margin: 0;
        &:before{
          width: auto;
          top: -40px;
          left: -60px;
        }
      }
    }
    @include mq(sp) {
      &__img{
        margin: 0 0 0 -20px;
        padding: 70% 0 0;
      }
      &__textarea{
        margin: 0;
      }
    }
  }
}