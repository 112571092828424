.c-lp-fair {
  background-color: palettes(main,lightest);
  padding: 110px 0 0;
  @include mq(sp) {
    padding: 72px 0 0;
  }
  &__ttl {
    font-size: 3.6rem;
    text-align: center;
    line-height: 1.4;
    margin: 0 0 40px;
    @include mq(sp) {
      font-size: 2.8rem;
    }
    span {
      font-family: $cinzelD;
      letter-spacing: .05em;
      font-weight: 400;
      display: block;
    }
    small {
      font-size: 1.2rem;
      letter-spacing: .05em;
      font-weight: 500;
      display: block;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    $spacePC: 20px;
    $spaceSP: 10px;
    margin: 0 (-1 * $spacePC);
    @include mq(sp) {
      margin: 0 (-1 * $spaceSP);
      padding: 0 20px;
    }
    .c-lp-caption {
      margin: 0 $spacePC 56px;
      @include mq(sp) {
        width: calc(50% - #{$spaceSP * 2});
        margin: 0 $spaceSP 40px;
      }
    }
  }
}