//-----------------------
.c-item-pager{
  display: flex;
  align-items: center;
  justify-content: center;
  &__arrow{
    a{
      width: 100%;
      height: 100%;
      display: block;
      transition: .4s ease;
      span{
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        line-height: 1.8;
        letter-spacing: .02em;
        position: relative;
        @include mq(sp){
          font-size: 1.4rem;
        }
        .iconSvg{
          display: block;
          width: 10px;
          height: 10px;
          @include mq(sp) {
            width: 8px;
            height: 8px;
          }
          svg{
            fill: $color_main;
            transition: fill .4s ease;
          }
        }
        &.-prev{
          margin-right: 30px;
          padding-right: 10px;
          flex-direction: row-reverse;
          .iconSvg{
            margin-right: 16px;
            transform: rotate(180deg);
          }
          @include mq(sp){
            margin-right: 20px;
            .iconSvg{
              margin-right: 10px;
            }
          }
        }
        &.-next{
          padding-left: 10px;
          margin-left: 30px;
          .iconSvg{
            margin-left: 16px;
          }
          @include mq(sp){
            margin-left: 20px;
            .iconSvg{
              margin-left: 10px;
            }
          }
        }
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: $color_text;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: .4s ease;
          opacity: 0;
        }
      }
      &:hover{
        span{
          &:after{
            opacity: 1;
          }
        }
      }
    }
  }
  &__btn{
    padding: 0 24px;
    border-left: 1px solid $color_gray;
    border-right: 1px solid $color_gray;
    @include mq(sp){
      padding: 0 20px;
    }
    a{
      font-size: 1.8rem;
      line-height: 1.8;
      letter-spacing: .02em;
      padding-bottom: 5px;
      transition: .4s ease;
      position: relative;
      @include mq(sp){
        font-size: 1.4rem;
      }
      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $color_text;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .4s ease;
        opacity: 0;
      }
      &:hover{
        &:after{
          opacity: 1;
        }
      }
    }
  }
  &.-numbers{
    @include mq(sp){
      .c-item-pager__arrow a span.-prev{
        margin-right: 0;
      }
      .c-item-pager__arrow a span.-next{
        margin-left: 0;
      }
    }
    .c-item-pager__btn{
      display: none;
    }
    ul{
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        width: 40px;
        height: 40px;
        font-size: 1.8rem;
        line-height: 1.8;
        letter-spacing: .02em;
        @include mq(sp){
          width: 36px;
          height: 36px;
        }
        &:nth-child(n+2){
          margin-left: 2px;
          @include mq(sp){
            margin-left: 4px;
          }
        }
        .c-item-pager__number{
          width: 100%;
          height: 100%;
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .-current{
          background: palettes(main,thin);
          color: #fff;
        }
        a{
          background: #fff;
          transition: .4s ease;
          &:hover{
            background: palettes(main,lightest);
          }
        }
      }
    }
  }
}